import { Component, forwardRef, Inject, OnInit, ViewChild } from "@angular/core";
import * as _ from 'lodash';
import { CustomPropertyDefinition } from "../../../../model";
import { AuthenticationService } from "../../../../service/authentication.service";
import { CountryService } from "../../../../service/country.service";
import { CustomPropertyService, CustomPropertyType } from "../../../../service/custom-property.service";
import { FieldService } from "../../../../service/field.service";
import { LocalizationPipe } from "../../../pipe";
import { AbstractAdvancedSearchComponent } from "../abstract-advanced-search.component";
import { PartnerAdvancedSearchAddPropertiesDialog } from "./partner-advanced-search-add-properties-dialog.component";

@Component({
    selector: 'partner-advanced-search',
    template: require('./partner-advanced-search.component.html'),
    styles: [require('../thing-advanced-search/thing-advanced-search.component.css')],
})
export class PartnerAdvancedSearchComponent extends AbstractAdvancedSearchComponent implements OnInit {

    @ViewChild(PartnerAdvancedSearchAddPropertiesDialog) addPropertiesDialog: PartnerAdvancedSearchAddPropertiesDialog;

    defaultProperties: { name: string, label: string }[] = [
        { name: 'name', label: 'partnerNameProperty' },
        { name: 'code', label: 'partnerCodeProperty' }
    ];

    private searchDataInitialized: boolean;
    private countries: { value: string, label: string }[];

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => LocalizationPipe)) localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => CountryService)) private countryService: CountryService,
        @Inject(forwardRef(() => FieldService)) fieldService: FieldService
    ) { super(localizationPipe, fieldService) }

    ngOnInit(): void {
        this.localStorageKey = this.queryId || 'partnerAdvancedSearchFieldsValues';
        this.savedFieldsValues = localStorage.getItem(this.localStorageKey) ? JSON.parse(localStorage.getItem(this.localStorageKey)) : null;
        this.properties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.Partner).filter(def => def.searchable);
        this.countries = this.countryService.getCountries().map(c => { return { value: c, label: c } });
        this.defaultSearchFields = ['code'];
        const userFields = this.defaultSearchFields.concat(_.cloneDeep(this.authenticationService.getUser().partnersSearchFields) || []);
        this.searchFields = this.getSearchFields(userFields)
        this.getPartnerProperties();
        this.fieldsPerRow = 2;
        if (this.query && this.query.length) {
            this.getEncodedQueryFields();
        }
        if (this.queryFieldRef) {
            this.subscribeToQueryFieldRef();
        } else {
            if (this.savedFieldsValues || this.alwaysExpanded) {
                this.showHideAdvancedSearch().then(() => this.waitForAdvancedSearchRenderedAndPerformSearch());
            } else if (this.encodedQueryFields) {
                this.loadData(null, this.encodedQueryFields);
            }
        }
    }

    advancedSearch($event?): void {
        this.simpleSearchKey = null;
        const rawValue = this.advancedSearchEditor.getObjectValue();
        const key = this.advancedSearchBarEditor.getObjectValue()['key'];
        const countries = rawValue['countries'] || null;
        const fields = {
            key: key,
            countries: countries
        };
        const fieldsToSave = {
            key: key,
            countries: countries
        };
        let encodedBody = Object.assign({}, fields, this.propertiesInputs ? this.propertiesInputs.getEncodedBody() : null);
        let fieldsToSaveBody = Object.assign({}, fieldsToSave, this.propertiesInputs ? this.propertiesInputs.getBody() : null);
        if (this.query && this.query.length) {
            encodedBody = Object.assign({}, encodedBody, this.encodedQueryFields);
            fieldsToSaveBody = this.removeQueryFields(fieldsToSaveBody);
        }
        this.updateLocalStorage(fieldsToSaveBody);
        const encodedBodyValues = Object.keys(encodedBody).map(el => encodedBody[el]);
        if (encodedBodyValues.some(el => el != null)) {
            this.loadData(key, encodedBody);
        } else {
            this.loadData();
        }
        if ($event) {
            const eventObject = $event.currentTarget;
            eventObject.blur();
        }
    }

    protected initConfigurations(): Promise<void> {
        let advancedSearchBarConfiguration = [];
        advancedSearchBarConfiguration.push({ name: 'key', type: 'SEARCH', value: this.getValue('key') || this.simpleSearchKey });
        this.advancedSearchBarConfiguration = advancedSearchBarConfiguration;
        return this.getSearchData().then(() => {
            let advancedSearchConfiguration = [];
            advancedSearchConfiguration.push({ name: 'countries', label: 'countryProperty', type: 'STRING', selectionMode: 'MAT_SELECTION', multipleSelection: true, values: this.countries, value: this.getValue('countries'), disabled: this.isQueryField('countries'), defaultValue: this.isQueryField('countries') ? this.getValue('countries') : null, placeholder: "All Countries" });
            this.advancedSearchConfiguration = advancedSearchConfiguration;
        });
    }

    private getSearchData(): Promise<void> {
        if (!this.searchDataInitialized) {
            this.searchDataInitialized = true;
        }
        return Promise.resolve();
    }

    getEncodedQueryFields(): void {
        let fields = [];
        this.query.forEach(el => {
            if (el.property == 'code' || this.advancedSearchAddibleProperties.some(p => p.name == el.property || "properties." + p.name == el.property)) {
                let propDef: CustomPropertyDefinition = null;
                if (this.properties.some(prop => el.property == "properties." + prop.name)) {
                    propDef = this.properties.find(prop => el.property == "properties." + prop.name);
                }
                fields[el.property] = this.getQueryValueWithSuffixes(el.value, el.predicate, propDef);
            } else {
                fields[el.property] = el.value;
            }
        });
        this.encodedQueryFields = fields;
    }

    openAddMorePropertiesDialog(): void {
        if (this.useExternalAddPropertiesDialog) {
            this.emitOpenExternalAddPropertiesDialogAction(this.advancedSearchAddibleProperties);
        } else {
            this.addPropertiesDialog.open();
        }
    }

    private getPartnerProperties(): void {
        this.advancedSearchAddibleProperties = [
            { name: 'name', label: 'partnerNameProperty' }
        ];
        if (this.properties) {
            this.updateAdvancedSearchAddibleProperties(this.properties, '', null);
        }
    }
}