import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, QueryList } from '@angular/core';
import * as _ from 'lodash';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { LOCALE_TIMEZONE } from '../../common/config';
import { Properties } from '../../common/properties';
import { CustomPropertyDefinition, DetailsWidgetData, Location as Loc, Metric, StatisticItem, Value } from '../../model/index';
import { LocationMetric } from '../../model/location-metric';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DataService } from '../../service/data.service';
import { FieldService } from '../../service/field.service';
import { FilterService } from '../../service/filter.service';
import { NetworkDataService } from '../../service/network-data.service';
import { SocketService } from '../../service/socket.service';
import { StatisticService } from '../../service/statistic.service';
import { CompositePartComponent, CompositePartMode, StatisticComponent } from '../../shared/component/index';
import { MetricAggregationType, MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { PropertyComponent } from '../../shared/component/property/property.component';
import { DefaultCompositePartPipe, DefaultContactsListPipe, StatisticPipe } from "../../shared/pipe/index";
import { DetailsWidgetService } from '../shared/details-widget.service';

@Injectable()
export class LocationDetailsService extends DetailsWidgetService<Loc> {

    private socketSubscriptionIds: number[];
    private statisticSubscriptions: { fieldsName: string[], subscriberId: string }[] = [];
    static nextId = 0;
    private stopSub: boolean;
    private startDate: number;
    private endDate: number;
    private fieldsNames: string[][] = [];
    private refreshMetricSubject = new Subject<string>();
    private refreshMetricSubscriptions: Subscription[];
    private timezone: string;

    constructor(
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => DataService)) protected dataService: DataService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NetworkDataService)) private networkDataService: NetworkDataService,
        @Inject(forwardRef(() => FilterService)) private filterService: FilterService
    ) {
        super(dataService, customPropertyService, authenticationService);
        this.timezone = this.authenticationService.getUser()?.timezone;
    }

    destroy(): void {
        this.stopSub = true;
        if (this.socketSubscriptionIds) {
            this.socketSubscriptionIds.forEach(id => {
                this.socketService.delete(id);
            });
            this.socketSubscriptionIds = null;
        }
        if (this.statisticSubscriptions) {
            this.statisticSubscriptions.forEach(sub => {
                this.fieldService.unsubscribeFromFields(sub.fieldsName);
            });
        }
        this.fieldsNames.forEach(names => {
            this.fieldService.unsubscribeFromFields(names);
        });
        if (this.refreshMetricSubscriptions && this.refreshMetricSubscriptions.length) {
            this.refreshMetricSubscriptions.forEach(subscription => subscription.unsubscribe());
        }
        this.refreshMetricSubscriptions = [];
        if (this.fieldServiceSubscriptions && this.fieldServiceSubscriptions.length) {
            this.fieldServiceSubscriptions.forEach(sub => sub.unsubscribe());
        }
    }

    init(components: QueryList<any>, location: Loc, startDate: number, endDate: number, metrics: Metric[], locationMetrics: LocationMetric[]): DetailsWidgetData[] {
        if (components && components.length) {
            this.stopSub = false;
            this.socketSubscriptionIds = [];
            this.element = location;
            this.startDate = startDate;
            this.endDate = endDate;
            this.refreshMetricSubscriptions = [];
            return components.map(component => this.processComponent(component, locationMetrics));
        }
        return [];
    }

    private processComponent(component: MetricDetailComponent | PropertyComponent | CompositePartComponent | StatisticComponent, locationMetrics: LocationMetric[]): DetailsWidgetData {
        let defaultValue = null;
        if (!(component instanceof StatisticComponent)) {
            defaultValue = this.getDefaultValue(component, this.MISSING_MEASURE_VALUE);
        }
        const subject: BehaviorSubject<any> = new BehaviorSubject(defaultValue);
        const statisticSubject: BehaviorSubject<{ statisticItems: StatisticItem[], filter: string | Function }> = new BehaviorSubject(null);
        if (component instanceof MetricDetailComponent) {
            const metricComponent = component as MetricDetailComponent;
            const locationMetric: LocationMetric = locationMetrics.find(m => m.name == component.name);
            const name = metricComponent.inputsFunction || [];
            this.fieldsNames.push(name);
            this.fieldServiceSubscriptions.push(this.fieldService.subscribeToFields(name)
                .pipe(takeWhile(() => !this.stopSub))
                .subscribe(fieldMap => {
                    const isAggregated = metricComponent.aggregation && metricComponent.aggregation != MetricAggregationType.LAST_VALUE;
                    let params = new HttpParams();
                    if (metricComponent.inputsFunction && metricComponent.inputsFunction.length > 0) {
                        metricComponent.inputsFunction.forEach(input => params = params.set(input, fieldMap[input]));
                    }
                    if (this.startDate) {
                        params = params.append('startDate', this.startDate + '')
                    }
                    if (this.endDate) {
                        params = params.append('endDate', this.endDate + '')
                    }
                    if (isAggregated) {
                        params = params.set('aggregation', metricComponent.aggregation);
                        if (!params.get("startDate")) {
                            params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
                        }
                    }

                    this.networkDataService.getLastValueByLocationIdAndMetricName(this.element.id, metricComponent.name, params)
                        .then(data => {
                            subject.next(data && data.value !== '' && data.value != undefined ? data.value : this.MISSING_MEASURE_VALUE);
                        })
                        .then(() => {
                            let refreshMetricSubscription = this.refreshMetricSubject.asObservable().subscribe(refreshMetricName => {
                                if (metricComponent.name == refreshMetricName) {
                                    this.networkDataService.getLastValueByLocationIdAndMetricName(this.element.id, metricComponent.name, params)
                                        .then(data => {
                                            subject.next(data && data.value !== '' && data.value != undefined ? data.value : this.MISSING_MEASURE_VALUE);
                                        })
                                }
                            });
                            this.refreshMetricSubscriptions.push(refreshMetricSubscription);
                        })
                        .catch(() => { });
                }));
            return {
                name: Promise.resolve(metricComponent.label || locationMetric.label || metricComponent.name),
                originalName: Promise.resolve(metricComponent.name),
                value: subject.asObservable(),
                filter: metricComponent.filter,
                unit: !this.filterService.isUnitAware(metricComponent.filter) ? (metricComponent.unit != null ? metricComponent.unit : locationMetric?.unit) : null,
                showLabel: metricComponent.showLabel,
                downloadable: false,
                metricNameOrPropertyId: metricComponent.name,
                customPropertyType: null,
                objId: null,
                description: metricComponent.description,
                filterArg: { metric: locationMetric, templateElement: metricComponent.getTemplateInputMap() }
            };
        } else if (component instanceof PropertyComponent) {
            const property = component as PropertyComponent;
            let isFile = false;
            let customPropertyType;
            let objId;
            let propertyDef: CustomPropertyDefinition;
            let unit = null;
            if (property.name.startsWith('customer.')) {
                const path = property.name.substr(9);
                const customer = this.element.customer;
                const propertyInfo = Properties.Customer[path];
                const name = path.startsWith('properties.') ? path.substr(11) : path;
                let node: any = customer;
                let defaultValue = '';
                let propertyPath = 'customer.properties.';
                if (component.name.startsWith(propertyPath)) {
                    customPropertyType = CustomPropertyType.Customer;
                    objId = customer.id;
                    propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, component.name.substring(propertyPath.length));
                    defaultValue = propertyDef ? propertyDef.value : '';
                    isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                }
                let value = _.get(node, path, defaultValue);
                if (propertyDef) {
                    value = this.getDictionaryValue(propertyDef, value);
                }
                subject.next(value);
                return {
                    name: propertyInfo ? Promise.resolve(property.label || propertyInfo.label || property.name) : this.getLabel(property, name, CustomPropertyType.Customer),
                    originalName: Promise.resolve(propertyInfo?.label || property.name),
                    value: subject.asObservable(),
                    filter: this.getFilterProperty(property) || (propertyInfo ? propertyInfo.defaultFilter : null),
                    unit: null,
                    showLabel: property.showLabel,
                    downloadable: isFile,
                    metricNameOrPropertyId: propertyDef ? propertyDef.id : null,
                    customPropertyType: customPropertyType,
                    objId: objId,
                    description: property.description || propertyDef?.description,
                    filterArg: propertyDef ? { property: propertyDef, templateElement: property.getTemplateInputMap() } : null
                };
            } else if (property.name.startsWith('properties')) {
                const customPropName = property.name.substr(11);
                let defaultValue = '';
                let propertyPath = 'properties.';
                customPropertyType = CustomPropertyType.Location;
                objId = this.element.id;
                propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, component.name.substring(propertyPath.length));
                defaultValue = propertyDef ? propertyDef.value : '';
                isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                let value = _.get(this.element, property.name, defaultValue);
                value = this.getDictionaryValue(propertyDef, value);
                subject.next(value);

                return {
                    name: this.getLabel(property, customPropName, CustomPropertyType.Location),
                    originalName: Promise.resolve(customPropName),
                    value: subject.asObservable(),
                    filter: this.getFilterProperty(property),
                    unit: null,
                    showLabel: property.showLabel,
                    downloadable: isFile,
                    metricNameOrPropertyId: propertyDef ? propertyDef.id : null,
                    customPropertyType: customPropertyType,
                    objId: objId,
                    description: property.description || propertyDef?.description,
                    filterArg: propertyDef ? { property: propertyDef, templateElement: property.getTemplateInputMap() } : null
                };
            } else {
                const propertyInfo = Properties.Location[property.name];
                let defaultValue = propertyInfo && (propertyInfo.path == 'country' || propertyInfo.path == 'timezone') ? _.get(this.element.customer, propertyInfo.path, '') : '';
                let path = propertyInfo ? propertyInfo.path : property.name;
                subject.next(_.get(this.element, path, defaultValue) || defaultValue);
                let metric = null;
                if (property.name.startsWith('metrics.')) {
                    let metricName = property.name.split('.')[1];
                    metric = locationMetrics.find(m => m.name == metricName);
                    if (metric) {
                        unit = metric.unit;
                    }
                }
                return {
                    name: Promise.resolve(property.label || (propertyInfo ? propertyInfo.label : null) || property.name),
                    originalName: Promise.resolve(propertyInfo?.label || property.name),
                    value: subject.asObservable(),
                    filter: property.filter || (propertyInfo ? propertyInfo.defaultFilter : null),
                    unit: !this.filterService.isUnitAware(property.filter as string) ? unit : null,
                    showLabel: property.showLabel,
                    downloadable: isFile,
                    metricNameOrPropertyId: propertyDef ? propertyDef.id : null,
                    customPropertyType: customPropertyType,
                    objId: objId,
                    description: property.description || metric?.description || propertyDef?.description,
                    filterArg: propertyDef ? { property: propertyDef, templateElement: property.getTemplateInputMap() } : null
                };
            }
        } else if (component instanceof CompositePartComponent) {
            const compositePart = component;
            this.element.constructor = Loc;
            const compositeSubject = compositePart.get(this.element, CompositePartMode.DETAIL, false, null, true);
            let refreshMetricSubscription = this.refreshMetricSubject.asObservable().subscribe(refreshMetricName => {
                if (compositePart.metrics && compositePart.metrics.some(metric => metric.name == refreshMetricName)) {
                    compositePart.get(this.element, CompositePartMode.DETAIL, false, null, true, compositeSubject);
                }
            });
            this.refreshMetricSubscriptions.push(refreshMetricSubscription);
            return {
                name: Promise.resolve(compositePart.label || compositePart.name),
                originalName: Promise.resolve(compositePart.name),
                value: compositeSubject.pipe(map(val => {
                    if (val) {
                        const v = val as Value;
                        return v.value;
                    }
                })),
                filter: compositePart.filter || DefaultCompositePartPipe,
                unit: null,
                showLabel: compositePart.showLabel,
                downloadable: false,
                metricNameOrPropertyId: null,
                customPropertyType: null,
                objId: null,
                description: compositePart.description
            };
        } else if (component instanceof StatisticComponent) {
            if (component.groupBy && component.groupBy.length > 2) {
                component.groupBy = component.groupBy.slice(0, 2);
            }
            let subscriberId = 'location_details_' + LocationDetailsService.nextId++;
            if (component.periodRef) {
                component.startDateFieldRef = null;
                component.endDateFieldRef = null;
            }
            this.fieldServiceSubscriptions.push(this.fieldService.subscribeToFields([component.startDateFieldRef, component.endDateFieldRef, component.periodRef]).subscribe(fieldsMap => {
                this.statisticService.getStatisticValue(component, fieldsMap).then(value => statisticSubject.next({ statisticItems: this.handleStatisticValue(value, component), filter: component.filter }), err => console.error(err))
            }));
            this.statisticSubscriptions.push({ fieldsName: [component.startDateFieldRef, component.endDateFieldRef, component.periodRef], subscriberId: subscriberId });
            return {
                name: Promise.resolve(component.label || component.name),
                originalName: Promise.resolve(component.name),
                value: statisticSubject.asObservable(),
                filter: StatisticPipe,
                unit: null,
                showLabel: true,
                downloadable: false,
                metricNameOrPropertyId: null,
                customPropertyType: null,
                objId: null,
                description: component.description
            };
        } else {
            throw new Error('Widget definition error: some components are not valid');
        }
    }

    private handleStatisticValue(value: any, component: StatisticComponent): any {
        const hasPeriodGroupBy = component.groupBy ? component.groupBy.some(el => StatisticService.PERIOD_GROUP_BY_LIST.find(period => period == el) != null) : false;
        if (value && value.length > 0) {
            return this.statisticService.sortStatisticItems(value, hasPeriodGroupBy, component);
        }
        return null;
    }

    private getLabel(property: PropertyComponent, name: string, type: CustomPropertyType): Promise<string> {
        if (property.label) {
            return Promise.resolve(property.label);
        }
        let cp = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(type, name)
        if (cp) {
            return Promise.resolve(cp.label || cp.name);
        } else {
            return Promise.resolve(property.name);
        }
    }

    private getFilterProperty(property: PropertyComponent): string | Function {
        if (property.name.startsWith('customer.properties.')) {
            const definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Customer, property.name.substr(20));
            if (definition && definition.type === 'CONTACTS') {
                return DefaultContactsListPipe;
            } else {
                return property.filter;
            }
        } else if (property.name.startsWith('properties.')) {
            const definitionLocation = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Location, property.name.substr(11));
            if (definitionLocation && definitionLocation.type === 'CONTACTS') {
                return DefaultContactsListPipe;
            } else {
                return property.filter;
            }
        } else {
            return property.filter;
        }
    }

    resetMetric(metric: LocationMetric, resetValue: string): Promise<void> {
        return this.networkDataService.resetLocationMetric(this.element.id, metric, resetValue);
    }

    updateRefreshSubject(metricName: string) {
        this.refreshMetricSubject.next(metricName);
    }

}