import { forwardRef, Inject, Injectable, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Properties } from '../../common/properties';
import { DetailsWidgetData, ProductModel, ProductModelPart } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService } from '../../service/custom-property.service';
import { DataService } from '../../service/data.service';
import { PropertyComponent } from '../../shared/component';
import { DetailsWidgetService } from '../shared/details-widget.service';

@Injectable()
export class ProductModelDetailsService extends DetailsWidgetService<ProductModel | ProductModelPart> {

    constructor(
        @Inject(forwardRef(() => DataService)) protected dataService: DataService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService
    ) {
        super(dataService, customPropertyService, authenticationService);
    }

    destroy(): void {
        // do nothing
    }

    init(components: QueryList<any>, productModel: ProductModel | ProductModelPart): DetailsWidgetData[] {
        if (components && components.length) {
            this.element = productModel;
            return components.map(component => this.processComponent(component));
        }
        return [];
    }

    private processComponent(component: PropertyComponent): DetailsWidgetData {
        const subject: BehaviorSubject<string> = new BehaviorSubject('');
        if (component instanceof PropertyComponent) {
            const property = component as PropertyComponent;
            const propertyInfo = Properties.ProductModel[property.name];
            subject.next(_.get(this.element, property.name, ''));
            return {
                name: Promise.resolve(property.label || propertyInfo?.label || property.name),
                originalName: Promise.resolve(propertyInfo?.label || property.name),
                value: subject.asObservable(),
                filter: property.filter,
                unit: null,
                showLabel: property.showLabel,
                downloadable: false,
                metricNameOrPropertyId: null,
                customPropertyType: null,
                objId: null,
                description: property.description,
                filterArg: null
            };
        } else {
            throw new Error('Widget definition error: some components are not valid');
        }
    }
}