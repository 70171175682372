import { Component, ContentChild, EventEmitter, forwardRef, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { normalizeValue } from '../../common/helper';
import { Contact, CustomPropertyDefinition, SelectOption } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { PatternValidationService } from '../../service/patternValidation.service';
import { DatetimeHelper } from '../utility/datetime-helper';

@Component({
    selector: 'custom-property-form',
    template: require('./custom-property-form.component.html')
})
export class CustomPropertyFormComponent implements OnInit {

    @Input() form: NgForm;

    @Input() type: CustomPropertyType;

    @Input() properties: { [propertyName: string]: string | number | Contact[] | { [propertyName: string]: string }[] };

    @Input() readonly: boolean;

    @Input() objId: string;

    @Input() hideGroupLead: boolean;

    @Input() set objThingDefId(objThingDefId: string) {
        if (objThingDefId != this._objThingDefId) {
            this._objThingDefId = objThingDefId;
            this.loadPropertyDefinitions();
        }
    };

    @Input() set customerType(customerType: string) {
        if (customerType != this._customerType) {
            this._customerType = customerType;
            this.loadPropertyDefinitions();
        }
    };

    @Input() set objTaskDefId(objTaskDefId: string) {
        if (objTaskDefId != this._objTaskDefId) {
            this._objTaskDefId = objTaskDefId;
            this.loadPropertyDefinitions();
        }
    };

    @Input() set country(country: string) {
        if (country != this._country) {
            this._country = country;
            this.loadPropertyDefinitions();
        }
    }

    @Input() groupFilter: string;

    @Input() showOnlyMandatory: boolean;

    @Input() set maintenanceType(maintenanceType: string) {
        if (maintenanceType != this._maintenanceType) {
            this._maintenanceType = maintenanceType;
            this.loadPropertyDefinitions();
        }
    }

    @Input() touchForError: boolean;

    @Output() fileDelete = new EventEmitter();

    @Output() fileUpload = new EventEmitter();

    @Output() countryChanged = new EventEmitter<string>();

    @ContentChild(TemplateRef) customElement: TemplateRef<any>;

    propertyDefinitions: CustomPropertyDefinition[] = [];
    propertiesFormGroup: FormGroup;
    groups: string[] = [null];
    allPlaceFields: { field: string, type: string }[] = [];
    _country: string;

    private _objThingDefId: string;
    private _objTaskDefId: string;
    private _customerType: string;
    private fileMap: { [propId: string]: { filename: string, file: File } } = {};
    private _maintenanceType: string;
    private MAPPING_ASSET_ID_PROPERTY_NAME = 'mappingAssetId';

    constructor(
        @Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AuthenticationService)) private authService: AuthenticationService,
        @Inject(forwardRef(() => PatternValidationService)) private patternValidationService: PatternValidationService
    ) { }

    getValue(definition: CustomPropertyDefinition): string | number | Contact[] | { [propertyName: string]: string }[] {
        if (this.properties && this.properties[definition.name] !== null && this.properties[definition.name] !== undefined) {
            return this.properties[definition.name];
        }
        return definition.value;
    }

    getObjectArrayValues(definition: CustomPropertyDefinition): { [propertyName: string]: string }[] {
        return this.getValue(definition) as { [propertyName: string]: string }[];
    }

    getContactsValue(definition: CustomPropertyDefinition): Contact[] {
        return this.getValue(definition) as Contact[];
    }

    getStringValue(definition: CustomPropertyDefinition): string {
        return this.getValue(definition) as string;
    }

    isDirty(): boolean {
        return this.form.dirty;
    }

    ngOnInit() {
        this.propertiesFormGroup = new FormGroup({});
        this.form.control.addControl('properties', this.propertiesFormGroup);
        if (this.type == CustomPropertyType.Task && this._objTaskDefId) {
            return;
        }
        this.loadPropertyDefinitions();
    }

    loadPropertyDefinitions(): void {
        let propertyDefinitions = this.customPropertyService.getCustomPropertyDefinitionByType(this.type);
        if (this.type == CustomPropertyType.Thing) {
            if (this._objThingDefId) {
                this.customPropertyService.getThingPropertyDefinitionsByThingDefinitionId(this._objThingDefId).then(thingPropDefs => {
                    propertyDefinitions = thingPropDefs.filter(property => !property.metric && !property.statisticBinding && (property.name != this.MAPPING_ASSET_ID_PROPERTY_NAME) && !property.algorithmsInsightBinding?.insightId);
                    this.managePropertyDefinitions(propertyDefinitions);
                });
            } else {
                let tenantPropertyDefs = propertyDefinitions.filter(property => !property.metric && !property.statisticBinding && !property.thingDefinition && (property.name != this.MAPPING_ASSET_ID_PROPERTY_NAME) && !property.algorithmsInsightBinding?.insightId);
                propertyDefinitions = tenantPropertyDefs;
                this.managePropertyDefinitions(propertyDefinitions);
            }
        } else if (this.type == CustomPropertyType.Task) {
            if (this._objTaskDefId) {
                this.customPropertyService.getTaskPropertyDefinitionsByTaskDefinitionId(this._objTaskDefId).then(taskPropDefs => {
                    this.managePropertyDefinitions(taskPropDefs);
                });
            }
        } else {
            if (this.type == CustomPropertyType.Customer) {
                propertyDefinitions = propertyDefinitions.filter(property => (!property.customerType || property.customerType == this._customerType) && !property.statisticBinding && !property.algorithmsInsightBinding?.insightId);
                if (this._country != 'Italy') {
                    propertyDefinitions = propertyDefinitions.filter(property => property.name != 'billing_sdi');
                }
            } else if (this.type == CustomPropertyType.MaintenanceWork) {
                propertyDefinitions = propertyDefinitions.filter(property => (property.maintenanceWorkTypes == null
                    || property.maintenanceWorkTypes.length == 0 || property.maintenanceWorkTypes.includes(this._maintenanceType)) && !property.statisticBinding && !property.algorithmsInsightBinding?.insightId);
            } else {
                propertyDefinitions = propertyDefinitions.filter(property => !property.statisticBinding && !property.algorithmsInsightBinding?.insightId);
            }
            if (this.type == CustomPropertyType.Customer || this.type == CustomPropertyType.User) {
                propertyDefinitions.filter(definition => definition.type == 'FISCAL_CODE').forEach(def => {
                    def.patternValidation = this.patternValidationService.getFiscalCodePatternByCountry(this._country);
                });
            }
            if (this.type == CustomPropertyType.User && !this.objId) {
                propertyDefinitions = propertyDefinitions.map(p => {
                    p.mandatory = p.mandatory && !p.providedDuringActivation;
                    return p;
                });
            }
            this.managePropertyDefinitions(propertyDefinitions);
        }
    }

    private managePropertyDefinitions(propertyDefinitions: CustomPropertyDefinition[]): void {
        let user = this.authService.getUser();
        propertyDefinitions = this.filterVisiblePropertyDefinitionByUserType(propertyDefinitions, user.userTypeId);
        if (this.showOnlyMandatory) {
            propertyDefinitions = propertyDefinitions.filter(prop => prop.mandatory);
        }
        this.manageGroups(propertyDefinitions);
        this.setAllPlaceTypes(propertyDefinitions);

        // clear old form controls
        this.propertyDefinitions?.forEach(oldProp => {
            if (!propertyDefinitions.some(prop => prop.name == oldProp.name)) {
                this.propertiesFormGroup?.removeControl(oldProp.name);
            }
        });
        this.propertyDefinitions = propertyDefinitions;
    }

    private manageGroups(propertyDefinitions: CustomPropertyDefinition[]) {
        this.groups = [null];
        if (this.groupFilter) {
            propertyDefinitions = propertyDefinitions.filter(property => property.group == this.groupFilter);
            this.groups = [];
        }
        propertyDefinitions.forEach(p => {
            let group = normalizeValue(p.group);
            if (!this.groups.some(g => g == group)) {
                this.groups.push(group);
            }
        });
    }

    private setAllPlaceTypes(propertyDefinitions): void {
        this.allPlaceFields = propertyDefinitions.filter(p => p.type == 'ADDRESS' || p.type == 'CITY' || p.type == 'STATE' || p.type == 'STREET_NUMBER' || p.type == 'ZIP_CODE')
            .map(p => { return { field: p.name, type: p.type } });
    }

    resetStatus(): any {
        this.form.form.markAsPristine();
        this.propertyDefinitions.forEach(definition => {
            if (definition.type !== 'CONTACTS') {
                if (definition.type == 'DATE') {
                    if (this.getValue(definition) != undefined) {
                        this.propertiesFormGroup.get(definition.name).reset(this.convertMillisToReadebleDate(this.getValue(definition).toString()));
                    } else {
                        this.propertiesFormGroup.get(definition.name).reset("");
                    }
                } else if (definition.type != 'FILE') {
                    const value = this.convertValue(definition);
                    this.propertiesFormGroup.get(definition.name).reset(value);
                } else {
                    this.propertiesFormGroup.get(definition.name).reset();
                }
            } else {
                if (!this.properties) {
                    this.properties = {};
                }
                if (this.properties[definition.name]) {
                    const contacts = this.properties[definition.name] as Array<Contact>;
                    this.properties[definition.name] = contacts && contacts.length > 0 ? contacts.slice() : [];
                } else {
                    this.properties[definition.name] = [];
                }
            }
        });

        return this.propertiesFormGroup.value;
    }

    getProperties(): any {
        if (!this.checkForm()) {
            return;
        }
        return this.propertyDefinitions.reduce((data, def) => {
            if (def.type == 'DATE') {
                if (this.propertiesFormGroup.get(def.name)) {
                    data[def.name] = this.convertReadebleDateToMillis(this.propertiesFormGroup.get(def.name).value)
                }
            } else {
                if (this.propertiesFormGroup.get(def.name)) {
                    data[def.name] = this.propertiesFormGroup.get(def.name).value;
                }
            }
            return data;
        }, {});
    }

    checkForm(): boolean {
        const requiredCustomProps = this.propertyDefinitions.filter(def => def.mandatory);
        const invalidCustomProps = requiredCustomProps.filter(def => {
            if (this.propertiesFormGroup.get(def.name)) {
                let value = this.propertiesFormGroup.get(def.name).value;
                if (def.type === 'BASE64' || def.type === 'FILE' || def.type === 'BLOB') {
                    // For untouched input[type=file] the value is null. We get the value from properties
                    value = value || this.getStringValue(def)
                }
                return value == undefined || value === '' || (value instanceof Array && value.length == 0);
            } else {
                return false;
            }
        });
        if (invalidCustomProps.length > 0) {
            invalidCustomProps.forEach(def => {
                this.propertiesFormGroup.get(def.name).setErrors({ 'requiredValidation': 'required' });
            })
        }
        return !(invalidCustomProps.length > 0);
    }

    convertMillisToReadebleDate(value: string): string {
        return DatetimeHelper.toReadable(value);
    }

    convertReadebleDateToMillis(value: string): string {
        return DatetimeHelper.toMillisString(value);
    }

    deleteFile(propId: string): void {
        this.customPropertyService.deleteFile(this.objId, propId, this.type).then(() => {
            delete this.fileMap[propId]
            this.fileDelete.emit("OK");
        }).catch(err => this.fileDelete.emit(err.error.message));
    }

    downloadFile(propId: string): void {
        this.customPropertyService.downloadFile(this.objId, propId, this.type);
    }

    uploadFiles(objId?: string): Promise<void> {
        if (!this.readonly) {
            return Object.entries(this.fileMap).reduce((cur, next) => {
                return cur.then(() => {
                    const propId = next[0]
                    const file = next[1]
                    return this.customPropertyService.saveFile((objId || this.objId), file.file, propId, this.type)
                });
            }, Promise.resolve());
        }
        return Promise.resolve();
    }

    updateFileMap(fileObj: { filename: string, file: File }, propId: string): void {
        this.fileMap[propId] = fileObj;
    }

    getPropertiesByGroup(group: string): CustomPropertyDefinition[] {
        return this.propertyDefinitions.filter(p => normalizeValue(p.group) == group);
    }

    private convertValue(definition: CustomPropertyDefinition): any {
        let value = this.getValue(definition);
        if (value != undefined && value !== '' && definition.type != 'FILE') {
            value = value.toString();
            if (definition.type != 'BOOLEAN' || (definition.type == 'BOOLEAN' && definition.selectionMode != null)) {
                if (definition.type == 'INTEGER' || definition.type == 'LONG' || definition.type == 'FLOAT' || definition.type == 'DOUBLE') {
                    if (definition.selectionMode == 'RADIO_BUTTON') {
                        return value.toString();
                    }
                    else if (definition.type == 'INTEGER' || definition.type == 'LONG') {
                        return parseInt(value);
                    } else {
                        return parseFloat(value);
                    }
                } else {
                    return value.toString();
                }
            } else {
                return (typeof value === 'boolean') ? value : (value === 'true');
            }
        } else {
            return '';
        }
    }

    private filterVisiblePropertyDefinitionByUserType(propertyDefinitions: CustomPropertyDefinition[], userTypeId: string): CustomPropertyDefinition[] {
        return propertyDefinitions.filter(prop => !prop.userTypeFiltered || !prop.userTypeIds || !prop.userTypeIds.length || prop.userTypeIds.includes(userTypeId));
    }

    getValues(definition: CustomPropertyDefinition): SelectOption[] {
        return definition.values;
    }

    addressChanged(addressData: { [fieldName: string]: string }): void {
        for (let fieldName of Object.keys(addressData)) {
            if (fieldName == 'country') {
                this.countryChanged.emit(addressData[fieldName]);
            } else {
                if (!this.properties) {
                    this.properties = {};
                }
                this.properties[fieldName] = addressData[fieldName];
            }
        }
    }
}