import { Component, forwardRef, Host, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { firstValueFrom, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ErrorMessages, Permissions } from '../../../common/constants';
import { THING_BY_ID } from '../../../common/endpoints';
import { Thing } from '../../../model';
import { AuthenticationService } from '../../../service/authentication.service';
import { FieldService } from '../../../service/field.service';
import { HttpService } from '../../../service/http.service';
import { ThingService } from '../../../service/thing.service';
import { ErrorUtility } from '../../../utility/error-utility';
import { AbstractThingContextService } from '../../class/abstract-thing-context-service.class';
import { maxValidator, minValidator, stepValidator } from "../../validator/index";
import { FormErrorPipe } from '../../pipe/form-error.pipe';
import { CustomLabelService } from '../../../service/custom-label.service';

@Component({
    selector: 'input-field',
    template: require('./input-field.component.html'),
    styles: [`

        span.input-group-text {
            background-color: white;
        }

        input:focus + div.input-group-append span  {
            border-color: #80bdff !important;
        }

        div.has-error input + div.input-group-append span  {
            border-color: red;
        }

        div.input-group-text {
            cursor: pointer;
        }

        div.disabled-input-group {
            cursor: not-allowed;
            opacity: 0.65;
        }
    `]
})
export class InputFieldComponent implements OnInit {

    @Input() id: string;

    @Input() label: string = '';

    @Input() min: number;

    @Input() max: number;

    @Input() step: number;

    @Input() mandatory: boolean = false;

    @Input() property: string;

    @Input() unit: string;

    @Input() placeholder: string = '';

    @Input() type: InputFieldType = InputFieldType.NUMBER;

    @ViewChild('form') form: NgForm;

    value: any;
    error: any = null;
    disabled: boolean;
    removeStepClass: string;
    addStepClass: string;

    private changeSub: Subscription;
    private hasWritePermissions: boolean;

    constructor(
        @Inject(forwardRef(() => ThingService)) private thingService: ThingService,
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => FormErrorPipe)) private formErrorPipe: FormErrorPipe,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService
    ) { }

    ngOnInit() {
        this.hasWritePermissions = this.authenticationService.hasPermission(Permissions.WRITE_THING);
        if (!this.hasWritePermissions) {
            this.disabled = true;
            this.setError("missingWriteThingPermissionProperty");
            return;
        }
        const currentThing = this.thingContextService.getCurrentThing();
        if (!currentThing) {
            this.disabled = true;
            this.setError("invalidContextProperty");
            return;
        }
        if (this.property) {
            if (this.property.startsWith('properties.')) {
                this.property = this.property.substring(11);
            }
            this.value = (currentThing.properties[this.property] as any);
        } else {
            this.disabled = true;
            this.setError("missingPropertyProperty");
            return;
        }

        setTimeout(() => {
            this.changeSub = this.form.valueChanges.pipe(debounceTime(1500)).subscribe(value => {
                this.handleValueChange();
            });
        })
    }

    private setError(key: string): void {
        this.labelService.getCustomLabel(key).then(msg => {
            this.error = msg;
        })
    }

    private handleValueChange(): void {
        if (!this.error) {
            const preUpdateThing = this.thingContextService.getCurrentThing();
            let currentThing = _.cloneDeep(preUpdateThing);
            if (this.hasWritePermissions && currentThing) {
                if (!currentThing.properties) {
                    currentThing.properties = {};
                }
                let properties = currentThing.properties;
                if (properties[this.property] != this.value) {
                    properties[this.property] = this.value;
                    this.saveThingProperties(currentThing).then(thing => {
                        this.thingService.updateCurrentThing(thing);
                    }).catch(err => {
                        this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR);
                    });
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.changeSub) {
            this.changeSub.unsubscribe();
        }
    }

    stepButtonPressed(subtraction?: boolean): void {
        if (this.disabled) {
            return;
        }
        let step = this.step ? Number(this.step) : 1;
        if (subtraction) {
            step = -step;
        }
        const oldValue = this.value;
        let newValue: number;
        if (oldValue != null) {
            newValue = Number(oldValue) + step;
        } else {
            newValue = 0 + step;
        }
        if (subtraction) {
            if (this.min == null || newValue >= this.min) {
                this.value = newValue;
                this.numberInputChanged();
            }
        } else {
            if (this.min == null || newValue <= this.max) {
                this.value = newValue;
                this.numberInputChanged();
            }
        }
    }

    checkMandatory(): void {
        if (this.mandatory && (this.value == null || this.value == "")) {
            this.formErrorPipe.transform('requiredValidation').then(msg => this.error = msg);
        } else {
            this.error = null;
        }
    }

    numberInputChanged(): void {
        if (this.value != null) {
            if (this.value < this.min) {
                this.formErrorPipe.transform('minValueValidation', { min: this.min }).then(msg => this.error = msg);
            } else if (this.value > this.max) {
                this.formErrorPipe.transform('maxValueValidation', { max: this.max }).then(msg => this.error = msg);
            } else {
                this.error = null;
            }
        } else {
            this.checkMandatory();
        }
    }

    getErrorKey(error: any): string {
        if (Object.keys(error).length > 0) {
            return Object.keys(error)[0];
        }
        return null;
    }

    getErrorInfo(error: any): string {
        if (Object.keys(error).length > 0) {
            const errorKey = Object.keys(error)[0];
            return error[errorKey];
        }
        return null;
    }

    private saveThingProperties(thing: Thing): Promise<Thing> {
        return firstValueFrom(this.httpService.put<Thing>(THING_BY_ID.replace('{id}', thing.id), thing));
    }
}

enum InputFieldType {
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN'
}