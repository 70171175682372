import { AfterContentInit, Component, ContentChildren, forwardRef, Inject, Input, NgZone, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { ErrorMessages, Permissions } from '../../../common/constants';
import { isEmpty } from '../../../common/helper';
import { DynamicListColumn } from '../../../dashboard-area/dynamic-list/dynamic-list-column';
import { DynamicListComponent } from '../../../dashboard-area/dynamic-list/dynamic-list.component';
import { EventDetailsPageDialogComponent } from '../../../dashboard-area/event-details/event-details-page-dialog.component';
import { AlertService } from '../../../dashboard-area/shared/alert.service';
import { Alert, AlertAcknowledgeStatus, AlertWorkSession, Customer, Location as Loc, Thing } from '../../../model/index';
import { AppService } from '../../../service/app.service';
import { AuthenticationService } from '../../../service/authentication.service';
import { CustomPropertyService } from '../../../service/custom-property.service';
import { DateRangeService } from '../../../service/date-range.service';
import { NavigationService } from '../../../service/navigation.service';
import { UiService } from '../../../service/ui.service';
import { CompositePartComponent, CompositePartMode, MessageComponent, ModalComponent } from '../../../shared/component';
import { PropertyComponent } from '../../../shared/component/property/property.component';
import { DurationFormatterPipe } from '../../../shared/pipe';
import { COMPONENT_DEFINITION_REF } from "../../../shared/utility/component-definition-token";
import { ErrorUtility } from '../../../utility/error-utility';
import { AlertWorkSessionList, DetailsModeType } from '../../shared/alert-work-session-list';

@Component({
    selector: 'active-alert-list-widget',
    template: require('./active-alert-list-widget.component.html'),
    styles: [require('./active-alert-list-widget.component.css')],
    providers: [DurationFormatterPipe, AlertService]
})
export class ActiveAlertListWidgetComponent extends AlertWorkSessionList implements OnInit, AfterContentInit, OnDestroy {

    @ViewChild('dynamicList') private dynamicList: DynamicListComponent;

    @Input() title: string;

    @Input() showHeader: boolean;

    @Input() exportEnabled: boolean = true;

    @Input() mode: string = "TABLE";

    @Input() expandable: boolean = true;

    @ContentChildren(COMPONENT_DEFINITION_REF) columns: QueryList<PropertyComponent | CompositePartComponent>;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    @ViewChild('confirmClear') confirmClear: ModalComponent;

    interval: any;
    writePermission: boolean;
    manualClear: boolean;
    error: string = null;
    message: string = 'updateAlertsMessage';

    static DEFAULT_COLUMN_NAMES = ['severity', 'category', 'name', 'title', 'description', 'date', 'duration'];

    private contextData: { customer: Customer, location: Loc, thing: Thing };
    private alertSubscription: Subscription;
    private alerts: Alert[];
    private checkedAlert: { alertDefinitionId: string, thingId: string }[] = [];

    constructor(
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => NavigationService)) navigationService: NavigationService,
        @Inject(forwardRef(() => CustomPropertyService)) customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => ActivatedRoute)) private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => NgZone)) private ngZone: NgZone,
        @Inject(forwardRef(() => AlertService)) private alertService: AlertService,
        @Inject(forwardRef(() => DurationFormatterPipe)) private durationFormatterPipe: DurationFormatterPipe,
        @Inject(forwardRef(() => DateRangeService)) protected dateRangeService: DateRangeService,
        @Inject(forwardRef(() => UiService)) private uiService: UiService,
        @Inject(forwardRef(() => MatDialog)) protected dialog: MatDialog
    ) {
        super(appService, navigationService, customPropertyService, dateRangeService, dialog);
    }

    ngOnInit(): void {
        this.title = this.title || 'activeAlertsTitle';
        if (this.showHeader === undefined) {
            this.showHeader = true;
        }
        this.init('activeAlertsEmptyMessage');
        this.mobile == true ? this.mode = 'LIST' : null;
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_ALERT_STATUS);
        this.manualClear = this.authenticationService.hasPermission(Permissions.CLEAR_ALERT);
    }

    ngAfterContentInit(): void {
        this.tableColumns = this.getTableColumns(this.columns, this.getDefaultColumnNames(), Alert);
        this.activatedRoute.data.subscribe(data => {
            this.contextData = (data as any);
            this.loadData(data);
        });
    }

    ngOnDestroy(): void {
        if (this.alertSubscription) {
            this.alertSubscription.unsubscribe();
            this.alertSubscription = null;
        }
        this.alertService.dispose();
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    getDefaultColumnNames(): string[] {
        return ActiveAlertListWidgetComponent.DEFAULT_COLUMN_NAMES;
    }

    isAckButtonEnabled(): boolean {
        if ((this.dynamicList && this.dynamicList.checkedId && this.dynamicList.checkedId.size > 0) && this.writePermission) {
            return true;
        }
        return false;
    }

    isManualClearButtonEnabled(): boolean {
        if ((this.dynamicList && this.dynamicList.checkedId) && this.manualClear) {
            if (this.checkedAlert.length != this.dynamicList.checkedId.size) {
                this.checkedAlert = [];
                if (this.dynamicList.checkedId.size > 0) {
                    Array.from(this.dynamicList.checkedId).forEach(id => {
                        const alert = this.alerts.find(al => al.id == id);
                        if (alert) {
                            this.checkedAlert.push({ alertDefinitionId: alert.alertDefinitionId, thingId: alert.thing.id });
                        }
                    });
                }
            }
            return this.checkedAlert.length > 0;
        }
        return false;
    }

    getValue(column: DynamicListColumn, alert: Alert): Observable<any> {
        let path = '';
        const columnName = column.name;

        if (column.compositePart) {
            return column.compositePart.get(alert, CompositePartMode.LIST);
        }
        let defaultValue = this.getDefaultPropertyValue(columnName) || '';
        let valueMap = this.getValueMap(columnName);

        if (columnName.startsWith('customer.')) {
            path = columnName;
        } else if (columnName.startsWith('location.')) {
            if (columnName === 'location.country' || columnName === 'location.timezone') {
                defaultValue = _.get(alert, path + columnName.replace('location', 'customer'), '');
            }
            path = columnName;
        } else if (columnName.startsWith('thing.')) {
            if (columnName == 'thing.serviceLevel') {
                path = 'thing.' + column.path;
            } else {
                path = columnName;
            }
        } else if (columnName.startsWith('thingDefinition.')) {
            path = columnName;
        } else {
            path = column.path;
        }
        let val = path === null ? alert : _.get(alert, path, defaultValue);
        if (valueMap && !isEmpty(val) && !isEmpty(valueMap[val])) {
            val = valueMap[val];
        }
        return of(val);
    }

    saveAll(): void {
        this.alertService.saveAckDeny(Array.from(this.dynamicList.checkedId), AlertAcknowledgeStatus.ACKNOWLEDGED).then(() => {
            this.saveMessage.show();
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.UPDATE_DATA_ERROR))
            .then(() => this.dynamicList.checkedId = new Set());
    }
    denyAll(): void {
        this.alertService.saveAckDeny(Array.from(this.dynamicList.checkedId), AlertAcknowledgeStatus.DENIED).then(() => {
            this.saveMessage.show();
            this.dynamicList.checkedId = new Set();
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.UPDATE_DATA_ERROR))
            .then(() => this.dynamicList.checkedId = new Set());
    }

    export(): void {
        this.alertService.exportActiveAlerts(this.contextData, this.columnFieldNames, this.columnFieldLabels);
    }

    private loadData(contextData: any): void {
        this.alertSubscription = this.alertService.loadActiveAlerts(contextData)
            .subscribe(alerts => {
                if (alerts) {
                    this.alerts = alerts;
                    this.alertWorkSessions = alerts;
                    this.computeInfoColumn();
                    this.refreshData();
                    this.refreshDuration();
                    this.loaded = true;
                }
            }, () => {
                this.loaded = true;
            });
    }

    private refreshDuration(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.ngZone.runOutsideAngular(() => {
            this.interval = setInterval(() => {
                this.ngZone.run(() => this.refreshData());
            }, 1000 * 60);
        });
    }

    private refreshData(): void {
        this.alertWorkSessions = this.alertWorkSessions.map(a => {
            a.endTimestamp = new Date().getTime();
            return a;
        });
        this.tableData = this.getTableData();
    }

    getDuration(alert: AlertWorkSession): string {
        return this.durationFormatterPipe.transform({ startTimestamp: alert.startTimestamp, endTimestamp: alert.endTimestamp });
    }

    clearAlerts(): void {
        this.confirmClear.hide();
        this.checkedAlert.forEach(ca => {
            if (ca.alertDefinitionId && ca.thingId) {
                this.alertService.clearManualAlerts(ca.thingId, ca.alertDefinitionId)
                    .catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.UPDATE_DATA_ERROR))
            }
        });
        this.dynamicList.checkedId = new Set();
        this.loadData(this.contextData);
    }

    openConfirmClear(): void {
        this.confirmClear.show();
    }

    cancelClear(): void {
        this.confirmClear.hide();
    }

    isCustomButtonVisible(alertWorkSession: Alert): boolean {
        return (!!alertWorkSession.templateName || !!this.uiService.getEventDetailsTemplate()) && alertWorkSession.thing != null;
    }

    goToAlertDetails(id: string): void {
        if (this.detailsMode == DetailsModeType.PAGE) {
            this.navigationService.navigateTo(['/dashboard/event_details', id]);
        } else if (this.detailsMode == DetailsModeType.POPUP) {
            this.openDialog(id);
        }
    }

    private computeInfoColumn(): void {
        if (this.alertWorkSessions.some(a => ((a as Alert).templateName || !!this.uiService.getEventDetailsTemplate()) && a.thing != null)) {
            this.showTemplateButton = true;
        }
    }

    private openDialog(id: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.data = { id: id };
        this.dialog.open(EventDetailsPageDialogComponent, dialogConfig);
    }
}