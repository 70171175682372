import { HttpParams } from "@angular/common/http";
import { Inject, Injectable, forwardRef } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ACTIVE_ALERTS } from "../../common/endpoints";
import { Thing } from "../../model";
import { HttpService } from "../../service/http.service";

@Injectable()
export class ManualEventReportDialogService {

    constructor(
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
    ) { }

    report(thing: Thing, alertDefinitionId: string, notes: string): Promise<void> {
        const params = this.getParams(thing, alertDefinitionId);
        const body = {
            notes: notes
        }
        return firstValueFrom(this.httpService.post<void>(ACTIVE_ALERTS, body, params));
    }

    private getParams(thing: Thing, alertDefinitionId: string): HttpParams {
        let params = new HttpParams();
        params = params.set('thingId', thing.id);
        params = params.set('alertDefinitionId', alertDefinitionId);
        return params;
    }

}