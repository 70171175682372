import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertSeverities } from '../../common/constants';
import { ACTIVE_ALERTS, ALERT_DEFINITIONS, ALERT_STATUS_BY_ID, ALERTS, ALERTS_EXPORT, ALERTS_TOPIC, HISTORICAL_ALERT_BY_ID, HISTORICAL_ALERTS, HISTORICAL_ALERTS_EXPORT, REMEDIES, USER_ALERTS_V2 } from '../../common/endpoints';
import { Alert, AlertAcknowledgeStatus, AlertCounter, AlertDefinition, AlertDefinitionRemedy, AlertRemedySelection, Customer, Location as Loc, PagedList, Thing, ThingAlertStatus, ThingDefinition } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpService } from '../../service/http.service';
import { SocketService } from '../../service/socket.service';
import { ThingContextService } from '../../service/thing-context.service';
import { HttpUtility } from '../../utility';

interface AlertDefinitionTypeTree {
    id: string;
    label: string;
    key: string;
    children: AlertDefinitionTypeTree[];
}

@Injectable()
export class AlertService {

    private alertsSubscriptionId: number;
    private alertCounterSubject: BehaviorSubject<AlertCounter>;
    private historicalAlerts: Alert[] = [];
    private historicalAlertsPageToken: string = null;
    private clear = null;

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService
    ) { }

    count(): Observable<AlertCounter> {
        if (!this.alertCounterSubject) {
            this.alertCounterSubject = new BehaviorSubject({ informationalCount: 0, warningCount: 0, criticalCount: 0 });
        }

        const fetchAlertCounter = () => {
            return this.getAlertCount().then(count => {
                this.alertCounterSubject.next(count);
            });
        };

        fetchAlertCounter().then(() => {
            const topic = this.getAlertsTopic();
            if (topic) {
                this.socketService.subscribe({
                    topic,
                    callback: () => fetchAlertCounter()
                });
            }

        });

        return this.alertCounterSubject.asObservable();
    }

    private getAlertCount(): Promise<any> {
        let params = new HttpParams();
        params = params.set('page', '0');
        params = params.set('size', '1');
        const severities = AlertSeverities;
        let promises = [];
        severities.forEach(severity => {
            params = params.set('severity', severity.value);
            promises.push(firstValueFrom(this.httpService.get<PagedList<Alert>>(USER_ALERTS_V2, params)));
        });
        return Promise.all(promises).then((results: PagedList<Alert>[]) => {
            const informationalCount = results[0].totalElements;
            const warningCount = results[1].totalElements;
            const criticalCount = results[2].totalElements + results[3].totalElements + results[4].totalElements;
            return { informationalCount: informationalCount, warningCount: warningCount, criticalCount: criticalCount };
        }).catch(err => console.error(err));
    }

    dispose(): void {
        if (this.alertsSubscriptionId) {
            this.socketService.delete(this.alertsSubscriptionId);
            this.alertsSubscriptionId = null;
        }
        if (this.clear) {
            clearInterval(this.clear);
            this.clear = null;
        }
    }

    getAlertsTopic(): string {
        return ALERTS_TOPIC.replace('{userId}', this.authenticationService.getUser().id);
    }

    public saveAckDeny(ids: string[], type: AlertAcknowledgeStatus): Promise<string> {
        const body = {
            alertsIds: ids,
            alertsToUpdateType: type
        };
        return this.httpService.put<string>(ALERTS, body).toPromise();
    }

    static createUrlParams(contextData: any): HttpParams {
        /* create the URL parameters depending on the context (i.e. adding customerId, locationId or thingId).*/
        const customer: Customer = contextData.customer;
        const loc: Loc = contextData.location;
        const thing: Thing = contextData.thing;
        let params = new HttpParams();
        if (thing) {
            params = params.append('thingId', thing.id);
        } else if (loc) {
            params = params.append('locationId', loc.id);
        } else if (customer) {
            params = params.append('customerId', customer.id);
        }
        const localeTimezone = moment_tz.tz.guess();
        if (localeTimezone) {
            params = params.append('clientTimezone', localeTimezone);
        }
        return params;
    }

    loadHistoricalAlert(columnNames: string[], firstPage: boolean, contextData: any, searchText?: string, startDate?: string, endDate?: string, severity?: string, alertDefinitonIds?: string[], thingDefinitionIds?: string[], locationAlertDefinitionIds?: string[]): Promise<Alert[]> {
        let params = this.buildHistoricalParams(columnNames, contextData, searchText, startDate, endDate, severity, alertDefinitonIds, thingDefinitionIds, locationAlertDefinitionIds);
        if (firstPage) {
            this.historicalAlerts = [];
            this.historicalAlertsPageToken = null;
        }
        if (this.historicalAlertsPageToken) {
            params = params.set('pageToken', this.historicalAlertsPageToken);
        }

        return this.httpService.get<{ historicalAlerts: Alert[], nextPageToken: string }>(HISTORICAL_ALERTS, params).pipe(map(resp => {
            const result: { historicalAlerts: Alert[], nextPageToken: string } = resp;
            this.historicalAlerts = firstPage ? result.historicalAlerts : this.historicalAlerts.concat(result.historicalAlerts);
            this.historicalAlertsPageToken = result.nextPageToken;
            return this.historicalAlerts;
        })).toPromise();
    }

    private buildHistoricalParams(fields: string[], contextData: any, searchText?: string, startDate?: string, endDate?: string, severity?: string, alertDefinitonIds?: string[], thingDefinitionIds?: string[], locationAlertDefinitionIds?: string[]): HttpParams {
        /* create the URL parameters depending on the context (i.e. adding customerId, locationId or thingId).*/
        let params = AlertService.createUrlParams(contextData);
        /* append to the URL parameters the fields to show */
        fields.forEach(c => params = params.append('field', c));
        if (fields.indexOf('location.country') >= 0) {
            params = params.append('field', 'customer.country')
        }
        if (fields.indexOf('location.timezone') >= 0) {
            params = params.append('field', 'customer.timezone')
        }
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        if (startDate) {
            params = params.set('startDate', startDate);
        }
        if (endDate) {
            params = params.set('endDate', endDate);
        }
        if (severity) {
            params = params.set('severity', severity);
        }
        if (alertDefinitonIds && alertDefinitonIds.length) {
            alertDefinitonIds.forEach(id => params = params.append('alertDefinitionId', id));
        }
        if (thingDefinitionIds && thingDefinitionIds.length) {
            thingDefinitionIds.forEach(id => params = params.append('thingDefinitionId', id));
        }
        if (locationAlertDefinitionIds && locationAlertDefinitionIds.length) {
            locationAlertDefinitionIds.forEach(id => params = params.append('locationAlertDefinitionId', id));
        }
        return params;
    }

    getActiveAlerts(contextData: any): Promise<Alert[]> {
        const params = AlertService.createUrlParams(contextData);
        return this.httpService.get<Alert[]>(ALERTS, params).pipe(map(resp => resp || [])).toPromise()
    }

    loadActiveAlerts(contextData: any): BehaviorSubject<Alert[]> {
        let subject = new BehaviorSubject(null);

        this.getActiveAlerts(contextData)
            .then(alerts => {
                this.zone.run(() => subject.next(alerts));
                const topic = this.getAlertsTopic();
                this.alertsSubscriptionId = this.socketService.subscribe({
                    topic,
                    callback: () => {
                        this.getActiveAlerts(contextData).then(alerts => this.zone.run(() => subject.next(alerts)));
                    }
                });
            }
            )
        return subject;
    }

    exportActiveAlerts(contextData: any, columnFieldNames: string[], columnFieldLabels: string[]) {
        let params = AlertService.createUrlParams(contextData);
        params = this.addSelectFieldsParams(columnFieldNames, columnFieldLabels, params);
        params = params.set('language', navigator.language);
        this.httpService.getFileWithName(ALERTS_EXPORT, 'alerts.csv', params).toPromise()
            .then(fileObj => this.httpUtility.wrapFileAndDownload(fileObj));
    }

    private addSelectFieldsParams(columnFieldNames: string[], columnFieldLabels: string[], params: HttpParams) {
        columnFieldNames.map(name => {
            if (name == 'date') {
                return 'startTimestamp';
            }
            return name;
        }).forEach(columnFieldName => params = params.append('selectField', columnFieldName));
        if (columnFieldLabels) {
            params = params.append('labels', columnFieldLabels.join(';'));
        }
        return params;
    }

    exportHistoricalAlerts(contextData: any, searchText: string, startDate: string, endDate: string, columnFieldNames: string[], columnFieldLabels: string[], severity?: string, alertDefinitonIds?: string[], thingDefinitionIds?: string[], locationAlertDefinitionIds?: string[]) {
        let params = this.buildHistoricalParams(columnFieldNames, contextData, searchText, startDate, endDate, severity, alertDefinitonIds, thingDefinitionIds, locationAlertDefinitionIds);
        params = this.addSelectFieldsParams(columnFieldNames, columnFieldLabels, params);
        params = params.set('language', navigator.language);
        this.httpService.getFileWithName(HISTORICAL_ALERTS_EXPORT, 'historical-alerts.csv', params).toPromise()
            .then(fileObj => this.httpUtility.wrapFileAndDownload(fileObj));
    }


    getAlertDefinitionTypes(thing: Thing): Promise<AlertDefinitionTypeTree[]> {
        return this.httpService.get<AlertDefinition[]>(ALERT_DEFINITIONS)
            .pipe(map(resp => {
                let alertDefs: AlertDefinition[] = resp;
                if (alertDefs) {
                    if (thing && thing.thingDefinitionId) {
                        const thingDefinitionId = thing.thingDefinitionId;
                        alertDefs = alertDefs.filter(alertDef => alertDef.thingDefinition.id === thingDefinitionId);
                    }
                    let userTypeId = this.authenticationService.getUser().userTypeId;
                    alertDefs = alertDefs.filter(alertDef => !alertDef.userTypeFiltered || (alertDef.userTypeIds && alertDef.userTypeIds.includes(userTypeId)));
                    alertDefs.sort((a, b) => (a.name > b.name) ? 1 : -1);
                }
                return this.buildTree(alertDefs);
            }))
            .toPromise()
            .catch(err => {
                console.error(err);
                return [];
            })
    }

    getAlertDefinitionTypesFromRemedies(): Promise<AlertDefinitionTypeTree[]> {
        return this.httpService.get<AlertDefinitionRemedy[]>(REMEDIES)
            .pipe(map(resp => {
                const remedies: AlertDefinitionRemedy[] = resp;
                let alertDefs = [];
                remedies.forEach(r => {
                    if (r.alertDefinition && !alertDefs.some(a => a.id == r.alertDefinition.id)) {
                        alertDefs.push(r.alertDefinition);
                    }
                });
                return this.buildTree(alertDefs);
            }))
            .toPromise()
            .catch(err => {
                console.error(err);
                return [];
            })
    }

    buildTree(alertDefs: AlertDefinition[]): AlertDefinitionTypeTree[] {
        const alertDefinitionsPerThingDefinition: { [thingDefinitionId: string]: AlertDefinition[] } = {};
        const thingDefinitions: { [id: string]: ThingDefinition } = {};
        const tree: AlertDefinitionTypeTree[] = [];

        if (alertDefs) {
            alertDefs.forEach(alertDef => {
                const thingDef = alertDef.thingDefinition;
                if (thingDef) {
                    if (!thingDefinitions[thingDef.id]) {
                        thingDefinitions[thingDef.id] = thingDef;
                    }
                    if (!alertDefinitionsPerThingDefinition[thingDef.id]) {
                        alertDefinitionsPerThingDefinition[thingDef.id] = [];
                    }
                    alertDefinitionsPerThingDefinition[thingDef.id].push(alertDef);
                }
            });

            const thingDefIds = Object.keys(thingDefinitions);
            thingDefIds.forEach(thingDefId => {
                const thingDef = thingDefinitions[thingDefId];
                const alertDefs = alertDefinitionsPerThingDefinition[thingDefId];
                const thingDefinitionNode: AlertDefinitionTypeTree = {
                    id: thingDefId,
                    key: 'thingDefinition',
                    label: thingDef.name,
                    children: null
                };
                if (alertDefs && alertDefs.length) {
                    thingDefinitionNode.children = alertDefs.map(alertDef => ({
                        id: alertDef.id,
                        key: 'alertDefinition',
                        label: alertDef.name,
                        children: null
                    }));
                    tree.push(thingDefinitionNode);
                }
            });
        }
        return tree;
    }

    updateSelection(alert: Alert, alertRemedySelections: AlertRemedySelection[]): Promise<void> {
        let endpoint = alert.endTimestamp ? HISTORICAL_ALERT_BY_ID : ALERT_STATUS_BY_ID;
        return this.httpService.patch<void>(endpoint.replace('{id}', alert.id), alertRemedySelections).toPromise();
    }

    clearManualAlerts(thingId: string, alertDefinitionId: string): Promise<void> {
        let params = new HttpParams();
        params = params.set('thingId', thingId);
        params = params.set('alertDefinitionId', alertDefinitionId);
        return this.httpService.delete<void>(ACTIVE_ALERTS, null, params).toPromise();
    }

    getAlertDefinitions(ignoreContext?: boolean, ignoreUserType?: boolean): Promise<AlertDefinition[]> {
        return firstValueFrom(this.httpService.get<AlertDefinition[]>(ALERT_DEFINITIONS)).then(alertDefs => {
            if (!ignoreContext && this.thingContextService.getCurrentThing() && this.thingContextService.getCurrentThing().thingDefinitionId) {
                const thingDefinitionId = this.thingContextService.getCurrentThing().thingDefinitionId;
                alertDefs = alertDefs.filter(alertDef => alertDef.thingDefinition.id === thingDefinitionId);
            }
            if (ignoreUserType) {
                let userTypeId = this.authenticationService.getUser().userTypeId;
                alertDefs = alertDefs.filter(alertDef => !alertDef.userTypeFiltered || (alertDef.userTypeIds && alertDef.userTypeIds.includes(userTypeId)));
            }
            alertDefs.sort((a, b) => (a.name > b.name) ? 1 : -1);
            return alertDefs;
        })
    }

    getPagedAlerts(page: number, size: number, sort: string[]): Promise<PagedList<ThingAlertStatus>> {
        let params = new HttpParams();
        params = params.set('page', page + '');
        params = params.set('size', size + '');
        if (sort && sort[0]) {
            params = params.set('sort', sort.join(','));
        }
        return firstValueFrom(this.httpService.get<PagedList<ThingAlertStatus>>(USER_ALERTS_V2, params));
    }

    getRecursivelyAllAlerts(page?: number, alerts?: ThingAlertStatus[]): Promise<ThingAlertStatus[]> {
        if (!page) {
            page = 0;
        }
        if (!alerts) {
            alerts = [];
        }
        return this.getPagedAlerts(page, 200, ['name', 'asc'])
            .then(pagedAlerts => {
                alerts = alerts.concat(pagedAlerts.content);
                if (pagedAlerts.last) {
                    return alerts;
                } else {
                    return this.getRecursivelyAllAlerts(++page, alerts);
                }
            });
    }
}