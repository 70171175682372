import { HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, ContentChild, Inject, Input, NgZone, OnDestroy, OnInit, ViewContainerRef, forwardRef } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { takeWhile } from 'rxjs/operators';
import { ErrorMessages, IncrementType } from "../../common/constants";
import { SOCKET_TOPIC_DATA_VALUES } from "../../common/endpoints";
import { Properties } from "../../common/properties";
import { CustomPropertyDefinition, Customer, Location, Metric, MetricValueType, NetworkMetric, Partner, StatisticItem, Thing, ThingDataItem, Value } from "../../model";
import { AuthenticationService } from "../../service/authentication.service";
import { CustomPropertyType } from "../../service/custom-property.service";
import { DataService } from "../../service/data.service";
import { DateRangeService } from "../../service/date-range.service";
import { ExpandableWidgetDialogService } from "../../service/expandable-widget-dialog.service";
import { FieldService } from "../../service/field.service";
import { FilterService } from "../../service/filter.service";
import { IncrementService } from "../../service/increment.service";
import { PropertyService } from "../../service/property.service";
import { SocketService } from "../../service/socket.service";
import { StatisticService } from "../../service/statistic.service";
import { AbstractContextService } from "../../shared/class/abstract-context-service.class";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { CompositePartComponent, CompositePartMode, MetricAggregationType, MetricDetailComponent, PropertyComponent, StatisticComponent } from "../../shared/component";
import { DatetimeFormatterPipe, DefaultCompositePartPipe } from "../../shared/pipe";
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { WidgetWithLink } from "../shared/widget-with-link";
import { SeverityInfo, ValueWidgetService, ValueWidgetState } from "./value-widget.service";

let nextId = 0;

@Component({
    selector: 'value-widget',
    template: require('./value-widget.component.html'),
    styles: [require('./value-widget.component.css')],
    providers: [ValueWidgetService]
})
export class ValueWidgetComponent extends WidgetWithLink implements OnInit, OnDestroy, AfterViewInit {

    @Input() showTitle: boolean = true;

    @Input() title: string;

    @Input() showDetailsBar: boolean = true;

    @Input() showIncrement: IncrementType = IncrementType.NONE;

    @Input() incrementStyle: IncrementStyleType = IncrementStyleType.POSITIVE;

    @Input() showPreviousValue: boolean;

    @Input() showLastUpdate: boolean;

    @Input() aggregation: MetricAggregationType = MetricAggregationType.LAST_VALUE;

    @Input() periodRef: string;

    @Input() queryRef: string;

    @Input() period: string;

    @Input() description: string;

    @Input() valueMode: ValueMode = ValueMode.ICON_AND_VALUE;

    @Input() showColors: boolean = true;

    @Input() icon: string;

    @Input() detailsTemplate: string;

    @Input() expandableMode: ExpandableDetailMode = ExpandableDetailMode.POPUP;

    @Input() popupTitle: string;

    @Input() detailsButtonIcon: string;

    @ContentChild(COMPONENT_DEFINITION_REF) private child: MetricDetailComponent | CompositePartComponent | PropertyComponent;

    @ContentChild(StatisticComponent) private statisticComponent: StatisticComponent;

    timezone: string;
    state: ValueWidgetState;
    unit: string;
    defaultIcon: string;
    isValueTruncated: boolean;
    incrementValues: any[];
    previousValue: { data: Value, dataLabel: string };
    valuePeriod: string;
    incrementPeriod: string;
    datetimeFormat: string = "LLL";
    valueIncreaseClass: string;
    valueDecreaseClass: string;
    nullIncreaseClass: string;
    severityInfo: SeverityInfo;
    decimalDigits: number = 2;
    language: string;
    hidden: boolean;
    expanded: boolean;
    expandIcon: string;

    private defaultValue: string = 'N/A';
    private isLocationMetric: boolean;
    private isMetricPrivate: boolean;
    private metric: Metric | NetworkMetric;
    private socketSubscriptionId: number;
    private dynamicValues: { [metricId: string]: any } = {};
    private dynamicValuesSubscriptions: Subscription[] = [];
    private socketSubscriberId: string;
    private numericTypes = [MetricValueType.DOUBLE, MetricValueType.FLOAT, MetricValueType.INTEGER, MetricValueType.LONG];
    private currentThing: Thing;
    private currentLocation: Location;
    private currentCustomer: Customer;
    private currentPartner: Partner;
    private alive: boolean = true;
    private statisticFieldsNames: string[];
    private subscription: Subscription;

    constructor(
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ValueWidgetService)) private valueWidgetService: ValueWidgetService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => DateRangeService)) private dateRangeService: DateRangeService,
        @Inject(forwardRef(() => DatetimeFormatterPipe)) private datetimeFormatterPipe: DatetimeFormatterPipe,
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => IncrementService)) private incrementService: IncrementService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => PropertyService)) private propertyService: PropertyService,
        @Inject(forwardRef(() => FilterService)) private filterService: FilterService,
        @Inject(forwardRef(() => ExpandableWidgetDialogService)) private expandableWidgetDialogService: ExpandableWidgetDialogService,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef
    ) { super(); }

    ngOnInit(): void {
        this.checkIfHidden();
        this.timezone = this.authenticationService.getUser()?.timezone;
        this.language = this.authenticationService.getUser()?.locale || this.authenticationService.getUser()?.language || 'en';
        this.socketSubscriberId = "value-widget-" + nextId++;
        if (this.thingContextService.getCurrentThing()) {
            this.currentThing = this.thingContextService.getCurrentThing();
        } else if (this.contextService.getCurrentLocation()) {
            this.currentLocation = this.contextService.getCurrentLocation();
        } else if (this.contextService.getCurrentCustomer()) {
            this.currentCustomer = this.contextService.getCurrentCustomer();
        } else {
            this.currentPartner = this.contextService.getCurrentPartner();
        }
        this.state = {
            data: { value: this.defaultValue, timestamp: null, unspecifiedChange: false },
            filter: undefined,
            loaded: false,
            error: null,
            dataLabel: null,
            filterArg: null
        };
        this.expandIcon = this.detailsButtonIcon || 'expand_content';
    }

    private checkIfHidden(): void {
        if (!this.thingContextService.getCurrentThing()) {
            if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForOrgPartner');
            } else {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForCustomer');
            }
        }
    }

    ngOnDestroy(): void {
        this.alive = false;
        this.deleteSocketSubscription();
        this.fieldService.unsubscribeFromFields([this.queryRef, this.periodRef]);
        if (this.dynamicValuesSubscriptions?.length) {
            this.dynamicValuesSubscriptions.forEach(s => {
                s.unsubscribe();
            });
            this.dataService.unsubscribeFromMetrics(this.socketSubscriberId);
        }
        if (this.statisticFieldsNames) {
            this.fieldService.unsubscribeFromFields(this.statisticFieldsNames);
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private deleteSocketSubscription(): void {
        if (this.socketSubscriptionId) {
            this.socketService.delete(this.socketSubscriptionId);
            this.socketSubscriptionId = null;
        }
    }

    ngAfterViewInit(): void {
        if (!this.child && !this.statisticComponent) {
            this.state = this.valueWidgetService.handleError('Error: sub element', null);
            return;
        }
        if (this.child.useDefaultNullValue) {
            this.defaultValue = this.authenticationService.getTenant().defaultNullValue;
            this.state.data.value = this.defaultValue;
        }
        if (this.child instanceof MetricDetailComponent) {
            this.processMetricDetailComponent(this.child);
        } else if (this.child instanceof CompositePartComponent) {
            this.processCompositeComponent(this.child);
        } else if (this.statisticComponent) {
            this.processStatisticComponent();
        } else if (this.child instanceof PropertyComponent) {
            this.processPropertyComponent(this.child);
        }
    }

    private processMetricDetailComponent(metricComponent: MetricDetailComponent): void {
        this.isLocationMetric = this.child.name.startsWith("location.") || (!this.currentThing && !this.child.name.startsWith("thing."));
        this.child.name = this.child.name.replace(/thing\.|location\./, "");
        this.aggregation = metricComponent.aggregation || this.aggregation;
        if (!this.valueWidgetService.isMetricAggregationValid(this.aggregation)) {
            this.state = this.valueWidgetService.handleError('Error: metric aggregation not supported', null);
            return;
        }
        this.valueWidgetService.getMetric(metricComponent, this.isLocationMetric, this.currentThing).then(metric => {
            if (!metric) {
                this.state = this.valueWidgetService.handleError('Error: metric is not defined', null);
                return;
            }
            this.metric = metric;
            this.subscribeToDynamicValues();
            this.title = this.title || metricComponent.label || metric?.label || metricComponent.name;
            this.unit = !this.filterService.isUnitAware(metricComponent.filter) ? (metricComponent.unit || metric?.unit) : null;
            this.defaultIcon = metricComponent.icon || this.icon;
            this.subscription = this.fieldService.subscribeToFields([this.queryRef, this.periodRef]).subscribe(fieldsMap => {
                this.state.loaded = false;
                this.deleteSocketSubscription();
                let params = this.valueWidgetService.getHttpParams(fieldsMap, this.period, this.periodRef, this.aggregation, this.showIncrement, this.showPreviousValue);
                this.loadMetricValues(metricComponent, fieldsMap, params).then(() => {
                    if (!this.isMetricPrivate && this.currentThing && !this.isLocationMetric && this.aggregation == MetricAggregationType.LAST_VALUE && this.valueWidgetService.isValidEndDate(parseInt(params.get('endDate')))) {
                        this.subscribeToSocket(metricComponent, fieldsMap, params);
                    }
                });
            });
        }).catch(err => this.state = this.valueWidgetService.handleError(ErrorMessages.GET_DATA_ERROR, err));
    }

    private loadMetricValues(metricComponent: MetricDetailComponent, fieldsMap: { [field: string]: any }, params: HttpParams): Promise<void> {
        if (this.showIncrement != IncrementType.NONE || this.showPreviousValue) {
            return this.getIncrementMetricValues(metricComponent, params, fieldsMap[this.queryRef]).then((data: { result: any, params: HttpParams, incrementParams: HttpParams }) => {
                this.isMetricPrivate = data.result.some(v => v?.privateData);
                this.handleIncrementValues(data, metricComponent);
            }).catch(err => {
                this.state = this.valueWidgetService.handleError('Error: unable to get data from server', err);
            });
        } else {
            return this.getSingleMetricValue(metricComponent, params, fieldsMap[this.queryRef]).then(data => {
                this.isMetricPrivate = !!data?.privateData;
                this.state = this.valueWidgetService.handleDataValue(data, metricComponent, this.defaultValue, this.metric, this.dynamicValues);
                this.severityInfo = this.valueWidgetService.updateSeverityInfo(this.metric, this.state.data.value, this.dynamicValues, this.unit, true);
            }).catch(err => {
                this.state = this.valueWidgetService.handleError('Error: unable to get data from server', err);
            });
        }
    }

    private getSingleMetricValue(metricComponent: MetricDetailComponent, params: HttpParams, queryObj?: object): Promise<Value> {
        if (this.isLocationMetric) {
            return this.valueWidgetService.getLocationMetricValue(metricComponent, params, this.aggregation, this.currentThing, this.currentLocation);
        } else if (this.currentThing) {
            return this.dataService.getLastValueByThingIdAndMetricName(this.currentThing.id, metricComponent.name, params);
        } else {
            return this.valueWidgetService.getAggregateValue(metricComponent, params, queryObj, this.currentLocation, this.currentCustomer, this.currentPartner).then(result => {
                return this.handleAggregateValue(result);
            });
        }
    }

    private handleAggregateValue(aggregateObject: any): Value {
        this.isValueTruncated = aggregateObject.truncated;
        return aggregateObject.value;
    }

    private getIncrementMetricValues(metricComponent: MetricDetailComponent, params: HttpParams, queryObj: object): Promise<any> {
        let incrementParams = _.cloneDeep(params);
        incrementParams = this.incrementService.setIncrementParams(incrementParams);
        let promises = [];
        promises.push(this.getSingleMetricValue(metricComponent, params, queryObj));
        promises.push(this.getSingleMetricValue(metricComponent, incrementParams, queryObj));
        return Promise.all(promises).then(results => {
            return {
                result: results,
                params: params,
                incrementParams: incrementParams
            };
        });
    }

    private subscribeToSocket(metricComponent: MetricDetailComponent, fieldsMap: any, params: HttpParams): void {
        let subscriber = {
            topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', this.currentThing.id).replace('{metricName}', this.metric.name),
            callback: () => {
                const newTimestamp = new Date().getTime();
                params = params.set('endDate', newTimestamp.toString());
                this.zone.run(() => {
                    this.loadMetricValues(metricComponent, fieldsMap, params);
                });
            }
        };
        this.socketSubscriptionId = this.socketService.subscribe(subscriber);
    }

    private handleIncrementValues(data: { result: any, params: HttpParams, incrementParams: HttpParams }, metricComponent: MetricDetailComponent): void {
        this.state = this.valueWidgetService.handleDataValue(data.result[0], metricComponent, this.defaultValue, this.metric, this.dynamicValues);
        this.severityInfo = this.valueWidgetService.updateSeverityInfo(this.metric, this.state.data.value, this.dynamicValues, this.unit, true);
        this.previousValue = this.valueWidgetService.handlePreviousDataValue(data.result[1], this.metric, this.dynamicValues);
        if (this.aggregation != MetricAggregationType.LAST_VALUE) {
            this.valuePeriod = this.dateRangeService.getPeriod(parseInt(data.params.get('startDate')), data.params.get("endDate") ? parseInt(data.params.get('endDate')) : moment().valueOf());
            this.incrementPeriod = this.dateRangeService.getPeriod(parseInt(data.incrementParams.get('startDate')), parseInt(data.incrementParams.get('endDate')));
        } else {
            const valueTimestamp = this.state.data.timestamp;
            const incrementTimestamp = this.previousValue.data.timestamp;
            this.valuePeriod = this.datetimeFormatterPipe.transform(valueTimestamp, this.datetimeFormat, this.timezone);
            this.incrementPeriod = this.datetimeFormatterPipe.transform(incrementTimestamp, this.datetimeFormat, this.timezone, this.language);
        }
        this.incrementValues = data.result.map((data: Value) => { return data && data.value != null ? data.value : null });
    }

    isDefaultValue(): boolean {
        return this.state.data.value == this.defaultValue;
    }

    isNumericMetric(): boolean {
        if (this.metric) {
            return this.numericTypes.includes(this.metric.valueType);
        } else {
            return false;
        }
    }

    private subscribeToDynamicValues(): void {
        if (this.currentThing) {
            this.thingContextService.getMetrics().then(metrics => {
                if (this.metric.ranges?.length && this.metric.ranges.some(r => r.toMetricId)) {
                    const dynamicRanges = this.metric.ranges.filter(range => range.toMetricId);
                    dynamicRanges.forEach(range => {
                        this.subscribeToMetric(range.toMetricId, metrics);
                    })
                }
                if (this.metric['minMetricId']) {
                    this.subscribeToMetric(this.metric['minMetricId'], metrics);
                }
                if (this.metric['maxMetricId']) {
                    this.subscribeToMetric(this.metric['maxMetricId'], metrics);
                }
            });
        }
    }

    private subscribeToMetric(id: string, metrics: Metric[]): void {
        const metric = metrics.find(m => m.id == id);
        if (metric) {
            const sub = this.dataService.subscribeToMetric(this.currentThing.id, metric.name, this.socketSubscriberId).subscribe(data => {
                if (data) {
                    this.updateDynamicValues(id, data.value);
                }
            });
            this.dynamicValuesSubscriptions.push(sub);
        }
    }

    private updateDynamicValues(metricId: string, value: any): void {
        this.dynamicValues[metricId] = value != undefined && value !== '' ? value : null;
        this.severityInfo = this.valueWidgetService.updateSeverityInfo(this.metric, this.state.data.value, this.dynamicValues, this.unit, true);
        if (this.previousValue?.data?.value != null) {
            this.previousValue.dataLabel = this.valueWidgetService.getDictionaryOrRangesLabel(this.previousValue.data.value, this.metric, this.dynamicValues, true);
        }
        if (this.state?.data?.value != null) {
            this.state.dataLabel = this.valueWidgetService.getDictionaryOrRangesLabel(this.state.data.value, this.metric, this.dynamicValues, true);
        }
    }

    getTimestamp(): number {
        return this.state?.data?.timestamp;
    }

    isValidIncrement(): boolean {
        return this.incrementValues && this.incrementValues.length > 1 && this.incrementValues.every(v => v != null);
    }

    private processCompositeComponent(compositePart: CompositePartComponent): void {
        if (compositePart.metrics.some(metric => !this.valueWidgetService.isMetricAggregationValid(metric.aggregation || this.aggregation))) {
            this.state = this.valueWidgetService.handleError('Error: metric aggregation not supported', null);
            return;
        }
        this.title = compositePart.label || compositePart.name || this.title;
        this.subscription = this.fieldService.subscribeToFields([this.queryRef, this.periodRef]).subscribe(fieldsMap => {
            let params = this.valueWidgetService.getHttpParams(fieldsMap, this.period, this.periodRef, this.aggregation, this.showIncrement, this.showPreviousValue);
            compositePart.get(this.currentThing, CompositePartMode.DETAIL, true, params)
                .pipe(takeWhile(() => this.alive))
                .subscribe(value => {
                    const v = value as Value;
                    this.state = {
                        data: v ? v : { value: null, timestamp: new Date().getTime(), unspecifiedChange: false },
                        filter: compositePart.filter || DefaultCompositePartPipe,
                        loaded: true,
                        error: null,
                        dataLabel: null,
                        filterArg: null
                    };
                });
        });
    }

    private processStatisticComponent(): void {
        this.title = this.title || this.statisticComponent.label;
        this.showLastUpdate = false;
        this.statisticComponent.periodRef = this.statisticComponent.periodRef || this.periodRef;
        if (this.statisticComponent.periodRef) {
            this.statisticComponent.startDateFieldRef = null;
            this.statisticComponent.endDateFieldRef = null;
        }
        this.statisticFieldsNames = [this.statisticComponent.startDateFieldRef, this.statisticComponent.endDateFieldRef, this.queryRef, this.statisticComponent.periodRef];
        this.subscription = this.fieldService.subscribeToFields(this.statisticFieldsNames).subscribe(fieldsMap => {
            if (this.showIncrement != IncrementType.NONE || this.showPreviousValue) {
                const periods: string[] = this.statisticService.getStatisticPeriods(this.statisticComponent, fieldsMap, this.currentThing, fieldsMap[this.queryRef], this.period);
                this.valuePeriod = periods[0];
                this.incrementPeriod = periods[1];
                this.statisticService.getStatisticValueWithIncrement(this.statisticComponent, fieldsMap, this.currentThing, fieldsMap[this.queryRef]).then((data: StatisticItem[][]) => {
                    this.state = this.valueWidgetService.handleStatisticItem(data[0], this.statisticComponent);
                    this.severityInfo = this.valueWidgetService.updateStatisticSeverityInfo(data[0]);
                    this.previousValue = this.valueWidgetService.handlePreviousStatisticItem(data[1]);
                    this.incrementValues = data.map((statisticItem: StatisticItem[]) => { return statisticItem && statisticItem.length > 0 ? statisticItem[0].value : null });
                }).catch(err => this.state = this.valueWidgetService.handleError('Error: unable to get data from server', err));
            } else {
                this.statisticService.getStatisticValue(this.statisticComponent, fieldsMap, this.currentThing, fieldsMap[this.queryRef], this.period)
                    .then(value => {
                        this.state = this.valueWidgetService.handleStatisticItem(value, this.statisticComponent);
                        this.severityInfo = this.valueWidgetService.updateStatisticSeverityInfo(value);
                    }).catch(err => this.state = this.valueWidgetService.handleError('Error: unable to get data from server', err));
            }
        });
    }

    private processPropertyComponent(propertyComponent: PropertyComponent): void {
        let contextObject: any;
        let propertyDef: CustomPropertyDefinition;
        let filter: string | Function;
        if (this.currentThing) {
            contextObject = this.currentThing;
            propertyDef = this.valueWidgetService.getPropertyDefinition(propertyComponent.name, CustomPropertyType.Thing);
            filter = this.valueWidgetService.getFilter(propertyComponent, propertyDef, Properties.Thing);
        } else if (this.currentLocation) {
            contextObject = this.currentLocation;
            propertyDef = this.valueWidgetService.getPropertyDefinition(propertyComponent.name, CustomPropertyType.Location);
            filter = this.valueWidgetService.getFilter(propertyComponent, propertyDef, Properties.Location);
        } else if (this.currentCustomer) {
            contextObject = this.currentCustomer;
            propertyDef = this.valueWidgetService.getPropertyDefinition(propertyComponent.name, CustomPropertyType.Customer);
            filter = this.valueWidgetService.getFilter(propertyComponent, propertyDef, Properties.Customer);
        } else if (this.currentPartner) {
            contextObject = this.currentPartner;
            propertyDef = this.valueWidgetService.getPropertyDefinition(propertyComponent.name, CustomPropertyType.Partner);
            filter = this.valueWidgetService.getFilter(propertyComponent, propertyDef, Properties.Partner);
        } else {
            contextObject = this.authenticationService.getUser();
            propertyDef = this.valueWidgetService.getPropertyDefinition(propertyComponent.name, CustomPropertyType.User);
            filter = propertyComponent.filter;
        }
        this.unit = !this.filterService.isUnitAware(propertyComponent.filter as string) ? propertyDef?.unit : null;
        const value = _.get(contextObject, propertyComponent.name, propertyDef?.value || this.defaultValue);
        this.state = this.valueWidgetService.handlePropertyData(value, filter, propertyDef, propertyComponent);
        if (propertyDef) {
            this.severityInfo = this.valueWidgetService.updateSeverityInfo(propertyDef, this.state.data.value, null, this.unit, false);
        }
        if (this.currentThing) {
            this.subscription = this.propertyService.subscribeToThingProperties(this.currentThing.id).subscribe((thingEvent: ThingDataItem[]) => this.updateProperties(thingEvent, propertyComponent, propertyDef));
        }
    }

    updateProperties(thingEvent: ThingDataItem[], propertyComponent: PropertyComponent, propertyDef: CustomPropertyDefinition): void {
        if (thingEvent) {
            thingEvent.forEach(thingDataItem => {
                const fieldName = PropertyService.getFieldName(thingDataItem);
                if (fieldName == propertyComponent.name) {
                    this.zone.run(() => {
                        this.state = this.valueWidgetService.handlePropertyData(thingDataItem.value, propertyComponent.filter, propertyDef, propertyComponent);
                        if (propertyDef) {
                            this.severityInfo = this.valueWidgetService.updateSeverityInfo(propertyDef, this.state.data.value, null, this.unit, false);
                        }
                    });
                }
            });
        }
    }

    toggleWidgetExpansion(event: Event): void {
        event.stopPropagation();
        if (this.expandableMode == ExpandableDetailMode.EMBEDDED) {
            this.expanded = !this.expanded;
        } else {
            this.expandableWidgetDialogService.openDialog({ title: this.popupTitle || this.title, templateName: this.detailsTemplate }, this.vcRef);
        }
    }
}

enum IncrementStyleType {
    POSITIVE = "POSITIVE",
    NEGATIVE = "NEGATIVE"
}

enum ValueMode {
    ICON_AND_VALUE = "ICON_AND_VALUE",
    ICON_AND_LABEL = "ICON_AND_LABEL",
    VALUE = "VALUE",
    LABEL = "LABEL",
    ICON = "ICON",
    NONE = "NONE"
}

enum ExpandableDetailMode {
    EMBEDDED = "EMBEDDED",
    POPUP = "POPUP"
}