import { DOCUMENT } from '@angular/common';
import { Component, forwardRef, Inject, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as $ from 'jquery';
import { take } from 'rxjs';
import { CSS_PATH, LIBS_PATH } from '../../common/config';
import { Permissions } from '../../common/constants';
import { Languages } from '../../common/languages';
import { UiProfileMainMenuStyle, UiProfilePage, UiProfileUserMenuStyle } from '../../model';
import { User } from '../../model/user';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { FormCheckerService } from '../../service/form-checker.service';
import { LogoutService } from '../../service/logout.service';
import { NavigationService } from '../../service/navigation.service';
import { SocketService } from '../../service/socket.service';
import { UiService } from '../../service/ui.service';
import { ViewService } from '../../service/view.service';
import { ModalComponent } from '../../shared/component/modal/modal.component';
import { UserService } from '../../shared/users/user.service';
import { ScriptHelper, Sorting } from '../../utility/index';
import { NavigationIndicatorIndeterminateComponent } from '../navigation-indicator/navigation-indicator.component';
import { TopbarDialogComponent } from '../topbar-dialog/topbar-dialog.component';

@Component({
	selector: 'dashboard',
	template: require('./dashboard.component.html'),
	styles: [require('./dashboard.component.css')]
})
export class DashboardComponent implements OnInit, OnDestroy {

	@ViewChild('unsavedDataAlert') private modal: ModalComponent;

	@ViewChild('navigationIndicator') private navigationIndicator: NavigationIndicatorIndeterminateComponent;

	currentUser: User;
	feedbackLinkVisible: boolean;
	boundedNavigateTo: Function;
	boundedLogout: Function;
	showAccountLink: boolean;
	showOrganizationLink: boolean;
	dashboardLoaded: boolean;
	sidebarBackgorundImg: string;
	isMobile: boolean;
	uiProfilePages: UiProfilePage[] = [];
	showToggleMenu: boolean;
	browserLanguage: string = 'en';
	languageSelectorEnabled: boolean;
	languages: { label: string, value: string }[] = [];
	profilePageInSidebar: boolean;
	topbarRight: UiProfilePage;
	topbarLeft: UiProfilePage;
	hiddenLabels: boolean;
	showPrivacyAndTerms: boolean;
	topbarPages: UiProfilePage[] = [];
	userMenuPages: UiProfilePage[] = [];

	private supportLocalStorage: boolean;
	private linkSources = [LIBS_PATH.DATATABLES, LIBS_PATH.DATATABLES_BOOTSTRAP, LIBS_PATH.DATE_RANGE_PICKER, LIBS_PATH.DATE_TIME_PICKER, LIBS_PATH.FILE_STYLE, LIBS_PATH.FLOAT_THEAD, LIBS_PATH.SOCK, LIBS_PATH.STOMP];
	private cssSources = [CSS_PATH.DATATABLES, CSS_PATH.DATE_RANGE_PICKER, CSS_PATH.DATE_TIME_PICKER];
	private sidebarClosed: boolean;

	constructor(
		@Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
		@Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
		@Inject(forwardRef(() => FormCheckerService)) private formCheckerService: FormCheckerService,
		@Inject(forwardRef(() => SocketService)) private socketService: SocketService,
		@Inject(forwardRef(() => Sorting)) private sorting: Sorting,
		@Inject(forwardRef(() => Router)) private router: Router,
		@Inject(forwardRef(() => NgZone)) private ngZone: NgZone,
		@Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef,
		@Inject(forwardRef(() => ViewService)) private viewService: ViewService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(forwardRef(() => Renderer2)) private renderer: Renderer2,
		@Inject(forwardRef(() => AppService)) private appService: AppService,
		@Inject(forwardRef(() => UiService)) private uiService: UiService,
		@Inject(forwardRef(() => UserService)) private userService: UserService,
		@Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
		@Inject(forwardRef(() => LogoutService)) private logoutService: LogoutService
	) {
		this.supportLocalStorage = !!window.localStorage;
		if (!this.supportLocalStorage) {
			console.warn('Local storage not supported: enable to memorize configuration parameters info!');
		}
	}

	ngOnInit(): void {
		this.viewService.getImageSrc("sidebarBackground").then(src => this.sidebarBackgorundImg = src);
		Promise.all([...this.linkSources.map(src => ScriptHelper.append(src, this.vcRef.element.nativeElement, false)),
		...this.cssSources.map(src => ScriptHelper.appendLinkCss(src, this.vcRef.element.nativeElement)), this.getLanguages()]).then(() => {
			this.uiService.waitForLoaded().then(() => {
				this.feedbackLinkVisible = this.checkFeedbackPermission();
				let uiProfile = this.uiService.getUiProfile();
				this.showToggleMenu = uiProfile.mainMenuCollapsable;
				this.uiProfilePages = this.uiService.getVisibleSidebarPages();
				this.topbarRight = this.uiService.getTopbarRight();
				this.topbarLeft = this.uiService.getTopbarLeft();
				this.topbarPages = this.uiService.getTopbarPages();
				this.userMenuPages = this.uiService.getUserMenuPages();
				this.profilePageInSidebar = uiProfile.userMenuStyle == UiProfileUserMenuStyle.SIDEBAR_ITEMS;
				this.dashboardLoaded = true;
				this.isMobile = this.appService.isMobile();
				this.subscribeToNavigationEvents();
				this.currentUser = this.authenticationService.getUser();
				this.computePermissionAndVisibilities();
				this.initializeTreeview(uiProfile.mainMenuStyle); // leave AdminLTE treeview initialization here otherwise it will not work
				this.showPrivacyAndTerms = !!this.authenticationService.getUserAgreements().length;
				this.boundedNavigateTo = this.navigateTo.bind(this);
				this.boundedLogout = this.logout.bind(this);
				this.formCheckerService.registerAlert(this.modal);
				this.ngZone.runOutsideAngular(() => {
					this.socketService.connect();
					this.sorting.loadAll();
				});
				if (this.isMobile) {
					this.addCustomMobileCss();
				}
				this.languageSelectorEnabled = this.authenticationService.getTenant()?.languageSelectorEnabled && this.languages?.length > 1;
			});
		});
	}

	private computePermissionAndVisibilities(): void {
		this.showAccountLink = (this.authenticationService.isCustomerUser() && this.authenticationService.hasPermission(Permissions.WRITE_CUSTOMER)) ||
			(this.authenticationService.isLocationUser() && this.authenticationService.hasPermission(Permissions.WRITE_LOCATION)) ||
			(this.authenticationService.isPartnerUser() && this.authenticationService.hasPermission(Permissions.WRITE_PARTNER))
		this.showOrganizationLink = this.authenticationService.isOrganizationUser() && this.authenticationService.hasPermission(Permissions.READ_ORGANIZATION);
	}

	private subscribeToNavigationEvents(): void {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.navigationIndicator.startNavigationIndicator();
			} else if (event instanceof NavigationEnd) {
				this.ngZone.runOutsideAngular(() => {
					setTimeout(() => {
						this.ngZone.run(() => {
							this.navigationIndicator.endNavigationIndicator();
						});
					}, 700);
				});
				this.feedbackLinkVisible = this.checkFeedbackPermission();
			} else if (event instanceof NavigationCancel) {
				this.ngZone.run(() => {
					this.navigationIndicator.endNavigationIndicator();
				});
			}
		});
	}

	private initializeTreeview(mainMenuStyle: UiProfileMainMenuStyle): void {
		let pushMenuElement = <any>$('[data-widget="pushmenu"]');
		if (this.isMobile) {
			this.renderer.removeClass(this.document.body, 'sidebar-mini');
			pushMenuElement.PushMenu('collapse');
			if (mainMenuStyle == UiProfileMainMenuStyle.ICON) {
				this.hiddenLabels = true;
			}
			this.sidebarClosed = true;
		} else if (mainMenuStyle == UiProfileMainMenuStyle.ICON) {
			pushMenuElement.PushMenu('collapse');
			this.hiddenLabels = true;
		} else if (this.supportLocalStorage && window.localStorage.getItem("menuLayout") == "collapsed") {
			pushMenuElement.PushMenu('collapse');
			this.sidebarClosed = true;
		} else {
			pushMenuElement.PushMenu('expand');
		}
		setTimeout(() => {
			let treeviewElement = <any>$('[data-widget="treeview"]');
			treeviewElement.Treeview('init');
		}, 100);
		$(document).on('shown.lte.pushmenu', () => {
			this.sidebarClosed = false;
			if (mainMenuStyle == UiProfileMainMenuStyle.ICON) {
				this.renderer.addClass(this.document.body, 'sidebar-mini-xs');
				this.renderer.addClass(this.document.body, 'sidebar-collapse');
			} else if (this.supportLocalStorage && window.localStorage.getItem("menuLayout") == "collapsed") {
				this.renderer.addClass(this.document.body, 'sidebar-collapse');
				this.sidebarClosed = true;
			}
		});
		$(document).on('collapsed.lte.pushmenu', () => {
			this.sidebarClosed = true;
			if (mainMenuStyle == UiProfileMainMenuStyle.ICON) {
				this.renderer.removeClass(this.document.body, 'sidebar-mini-xs');
			}
		});
	}

	private addCustomMobileCss(): void {
		// hide mat-tab arrows since tabs are now scrollable
		let myCss = ".mat-tab-header-pagination-before, .mat-tab-header-pagination-after { display: none !important; }";

		// resize mat-tab-group
		myCss += `\n.mat-tab-group .mat-tab-label {
            font-size: 12px;
            font-weight: 700;
            height: 35px;
            box-shadow: 3px 3px 3px rgb(0 0 0/ 20%);
            min-width: 120px;
        }`;
		var style = document.createElement("STYLE");
		style.innerText = myCss;
		document.body.appendChild(style);
	}

	ngOnDestroy(): void {
		this.formCheckerService.unregisterAlert();
		this.socketService.disconnect();
	}

	logout(): void {
		this.logoutService.logout();
	}

	navigateTo(url: string): void {
		this.navigationService.navigateTo([url]);
		if (!url.match("/dashboard/feedback")) {
			this.navigationService.reset();
		}
		if (this.isMobile) {
			this.renderer.removeClass(this.document.body, 'sidebar-mini-xs');
		}
		this.closeMenuIfIsSmallPage();
	}

	private closeMenuIfIsSmallPage() {
		// closes the menu if is totally collapsed like a mobile page
		if (this.document.body.classList.contains('sidebar-open')) {
			this.renderer.removeClass(this.document.body, 'sidebar-open');
			this.renderer.addClass(this.document.body, 'sidebar-closed');
			this.renderer.addClass(this.document.body, 'sidebar-collapse');
		}
	}

	isActive(url: string) {
		if (this.router.url.includes("location_details")) {
			if (this.authenticationService.isCustomerUser()) {
				return url == '/dashboard/locations';
			} else {
				return url == '/dashboard/customers';
			}
		} else if (this.router.url.includes("thing_details")) {
			if (this.authenticationService.isCustomerUser()) {
				return url == '/dashboard/locations';
			} else if (this.authenticationService.isLocationUser()) {
				return url == '/dashboard/things';
			} else {
				return url == '/dashboard/customers';
			}
		} else {
			return this.router.url == url || this.router.url.startsWith(url + '/') || this.router.url.startsWith(url + '?') || this.router.url.startsWith(url.slice(0, -1) + '_details');
		}
	}

	isUserVerifier(): boolean {
		return this.authenticationService.isUserVerificator();
	}

	isCustomer(): boolean {
		return this.authenticationService.isCustomerUser();
	}

	toggleMenu(): void {
		this.setLocalStorageMenu();
	}

	private setLocalStorageMenu(): void {
		if (this.supportLocalStorage) {
			let currentValue = this.sidebarClosed ? 'collapsed' : 'expandend';
			if (currentValue == 'collapsed') {
				window.localStorage.setItem('menuLayout', 'expandend');
			} else {
				window.localStorage.setItem('menuLayout', 'collapsed');
			}
		}
	}

	getCustomPageId(name: string) {
		let string = name.replace(/[^a-z0-9]/gi, ' ').replace(/(?:(\s+.))/g, function (match) {
			return match.charAt(match.length - 1).toUpperCase();
		}).trim();
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	private checkFeedbackPermission(): boolean {
		let tenant = this.authenticationService.getTenant();
		if (tenant && tenant.enableFeedbackPageLink) {
			return this.router.url.indexOf("/dashboard/feedback") < 0 && !this.router.url.match(/\/dashboard\/thing_details\/[0-9A-Za-z]+\/reports/g);
		}
		return false;
	}

	changeLanguage(language: string): void {
		this.userService.patchLanguage(language).then(() => {
			window.location.reload();
		}).catch(err => {
			console.log(err);
		});
	}

	private getLanguages(): Promise<void> {
		return this.userService.getTextLanguages().then(configuredLanguages => {
			this.languages = Object.keys(Languages).map(k => { return { label: Languages[k], value: k } }).filter(el => el.value == 'en' || configuredLanguages.includes(el.value));
			this.browserLanguage = (this.languages.find(el => el.value == navigator.language.substring(0, 2)) ? navigator.language : 'en').substring(0, 2);
		}).catch(err => {
			console.log(err);
			this.languages = [];
		});
	}

	openTopLeft(): void {
		this.openTopbar(true);
	}

	openTopRight(): void {
		this.openTopbar(false);
	}

	private openTopbar(isLeft: boolean) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.autoFocus = false;
		dialogConfig.minWidth = '100%';
		dialogConfig.maxHeight = '75%';
		dialogConfig.position = { top: '0px' };
		dialogConfig.data = { isLeft: isLeft, page: isLeft ? this.topbarLeft : this.topbarRight, otherPage: isLeft ? this.topbarRight : this.topbarLeft };
		const dialogRef = this.dialog.open(TopbarDialogComponent, dialogConfig);
		this.navigationService.setTopbarRef(dialogRef);
		this.router.events.pipe(take(1)).subscribe(() => dialogRef.close());  // dialog closes if a navigation event is triggered
	}

	openExternalUrl(url: string): void {
		if (this.appService.isMobile() && window['mobileUtils']?.openURLExternally) {
			window['mobileUtils'].openURLExternally(url);
		} else {
			window.open(url, "_blank");
		}
	}

}
