import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, NgZone, QueryList } from '@angular/core';
import * as _ from 'lodash';
import * as moment_tz from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { LOCALE_TIMEZONE } from '../../common/config';
import { SOCKET_TOPIC_DATA_VALUES } from '../../common/endpoints';
import { isEmpty } from '../../common/helper';
import { Properties } from '../../common/properties';
import { ThingSimService } from '../../dashboard-area/thing/thing-sim/thing-sim.service';
import { CustomPropertyDefinition, DetailsWidgetData, Metric, MetricType, StatisticItem, Thing, ThingDataItem, ThingDefinition, Value } from '../../model/index';
import { LocationMetric } from '../../model/location-metric';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DataService } from '../../service/data.service';
import { FieldService } from '../../service/field.service';
import { FilterService } from '../../service/filter.service';
import { MetricService } from '../../service/metric.service';
import { PropertyService } from '../../service/property.service';
import { SocketService } from '../../service/socket.service';
import { StatisticService } from '../../service/statistic.service';
import { ThingService } from '../../service/thing.service';
import { CompositePartComponent, CompositePartMode, StatisticComponent } from '../../shared/component/index';
import { MetricAggregationType, MetricDetailComponent } from '../../shared/component/metric/metric-detail.component';
import { PropertyComponent } from '../../shared/component/property/property.component';
import { DefaultCompositePartPipe, DefaultContactsTablePipe, StatisticPipe } from "../../shared/pipe/index";
import { ObjectUtility } from '../../utility';
import { DetailsWidgetService } from '../shared/details-widget.service';

@Injectable()
export class ThingDetailsWidgetService extends DetailsWidgetService<Thing> {

    private socketSubscriptionIds: number[];
    private stopSub: boolean;
    private thingSubject: BehaviorSubject<ThingDataItem[]>;
    private propertySubjects: { [fieldName: string]: BehaviorSubject<any> } = {};
    private thingDefinition: ThingDefinition;
    private startDate: number;
    private endDate: number;
    private fieldsNames: string[][] = [];
    private metricValueTimestampMap: { [metricId: string]: number } = {};
    static nextId = 0;
    private timezone: string;

    constructor(
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => DataService)) protected dataService: DataService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => PropertyService)) private propertyService: PropertyService,
        @Inject(forwardRef(() => StatisticService)) private statisticService: StatisticService,
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ObjectUtility)) private objUtils: ObjectUtility,
        @Inject(forwardRef(() => ThingSimService)) private thingSimService: ThingSimService,
        @Inject(forwardRef(() => FilterService)) private filterService: FilterService
    ) {
        super(dataService, customPropertyService, authenticationService);
        this.timezone = this.authenticationService.getUser()?.timezone;
    }

    destroy(): void {
        this.stopSub = true;
        if (this.socketSubscriptionIds) {
            this.socketSubscriptionIds.forEach(id => {
                this.socketService.delete(id);
            });
            this.socketSubscriptionIds = null;
        }
        for (let fieldName in this.propertySubjects) {
            let propertySubject = this.propertySubjects[fieldName];
            propertySubject.unsubscribe();
        }
        this.propertySubjects = {};
        if (this.element) {
            this.propertyService.usubscribeFromThingProperties(this.element.id);
        }
        this.fieldsNames.forEach(names => {
            this.fieldService.unsubscribeFromFields(names);
        });
        this.thingSimService.dispose();
        if (this.fieldServiceSubscriptions && this.fieldServiceSubscriptions.length) {
            this.fieldServiceSubscriptions.forEach(sub => sub.unsubscribe());
        }
    }

    init(components: QueryList<any>, thing: Thing, startDate: number, endDate: number, metrics: Metric[], locationMetrics: LocationMetric[]): DetailsWidgetData[] {
        this.element = thing;
        this.thingDefinition = thing.thingDefinition;
        this.startDate = startDate;
        this.endDate = endDate;
        if (components && components.length) {
            this.stopSub = false;
            this.socketSubscriptionIds = [];
            this.thingSubject = this.propertyService.subscribeToThingProperties(this.element.id);
            this.thingSubject.subscribe((thingEvent: ThingDataItem[]) => this.updateProperties(thingEvent));
            if (components.some(c => c instanceof PropertyComponent && c.name.startsWith("simDetails."))) {
                this.thingSimService.init(thing);
            }
            return components.map(component => this.processComponent(component, metrics, locationMetrics));
        }
        return [];
    }

    private processComponent(component: MetricDetailComponent | PropertyComponent | CompositePartComponent | StatisticComponent, metrics: Metric[], locationMetrics: LocationMetric[]): DetailsWidgetData {
        let defaultValue = null;
        if (!(component instanceof StatisticComponent)) {
            defaultValue = this.getDefaultValue(component, this.MISSING_MEASURE_VALUE);
        }
        const subject: BehaviorSubject<any> = new BehaviorSubject(defaultValue);
        if (component instanceof MetricDetailComponent) {
            const metricComponent = component as MetricDetailComponent;
            const metric: Metric = metrics.find(m => m.name == component.name);
            const isBlob: boolean = metric.type == MetricType.BLOB;
            const name = metricComponent.inputsFunction || [];
            this.fieldsNames.push(name);
            this.fieldServiceSubscriptions.push(this.fieldService.subscribeToFields(name)
                .pipe(takeWhile(() => !this.stopSub))
                .subscribe(fieldMap => {
                    const isAggregated = metricComponent.aggregation && metricComponent.aggregation != MetricAggregationType.LAST_VALUE;
                    let params = new HttpParams();
                    if (metricComponent.inputsFunction && metricComponent.inputsFunction.length > 0) {
                        metricComponent.inputsFunction.forEach(input => params = params.set(input, fieldMap[input]));
                    }
                    if (this.startDate) {
                        params = params.append('startDate', this.startDate + '')
                    }
                    if (this.endDate) {
                        params = params.append('endDate', this.endDate + '')
                    }
                    if (isAggregated) {
                        params = params.set('aggregation', metricComponent.aggregation);
                        if (!params.get("startDate")) {
                            params = params.set('startDate', moment_tz.tz(this.timezone || LOCALE_TIMEZONE).subtract(7, 'days').startOf('day').valueOf().toString());
                        }
                    }

                    this.dataService.getLastValueByThingIdAndMetricName(this.element.id, metricComponent.name, params)
                        .then(data => {
                            if (data && data.privateData) {
                                this.metricValueTimestampMap[metricComponent.name] = null;
                                subject.next(this.getValue(null, isBlob, defaultValue));
                                return false;
                            } else {
                                this.metricValueTimestampMap[metricComponent.name] = data ? data.timestamp : null;
                                subject.next(this.getValue(data, isBlob, defaultValue));
                                return true;
                            }
                        })
                        .then(shouldSubscribe => {
                            if (shouldSubscribe && !this.endDate && !isAggregated) {
                                const socketSubscriptionId = this.socketService.subscribe({
                                    topic: SOCKET_TOPIC_DATA_VALUES.replace('{thingId}', this.element.id).replace('{metricName}', metricComponent.name),
                                    callback: message => {
                                        const data = JSON.parse(message.body);
                                        if (data.unspecifedChange) {
                                            const params = new HttpParams();
                                            if (metricComponent.inputsFunction && metricComponent.inputsFunction.length > 0) {
                                                metricComponent.inputsFunction.forEach(input => params.set(input, fieldMap[input]));
                                            }
                                            this.dataService.getLastValueByThingIdAndMetricName(this.element.id, metricComponent.name, params)
                                                .then(data => {
                                                    this.metricValueTimestampMap[metricComponent.name] = data.timestamp;
                                                    this.zone.run(() => subject.next(this.getValue(data, isBlob, defaultValue)));
                                                })
                                        } else {
                                            let oldDataTimestamp = this.metricValueTimestampMap[metricComponent.name];
                                            if (!oldDataTimestamp || oldDataTimestamp <= data.timestamp) {
                                                this.metricValueTimestampMap[metricComponent.name] = data.timestamp;
                                                const newData: Value = {
                                                    unspecifiedChange: data.unspecifiedChange,
                                                    timestamp: data.timestamp,
                                                    value: DataService.extractValue(data.values)
                                                };
                                                this.zone.run(() => subject.next(this.getValue(newData, isBlob, defaultValue)));
                                            }
                                        }
                                    }
                                });
                                this.socketSubscriptionIds.push(socketSubscriptionId);
                            }
                        })
                        .catch(() => { });
                }));
            return {
                name: Promise.resolve(metricComponent.label || metric.label || metricComponent.name),
                originalName: Promise.resolve(metricComponent.name),
                value: subject.asObservable(),
                filter: MetricService.getMetricFilter(metricComponent),
                unit: !this.filterService.isUnitAware(metricComponent.filter) ? (metricComponent.unit != null ? metricComponent.unit : metric.unit) : null,
                showLabel: metricComponent.showLabel,
                downloadable: isBlob,
                metricNameOrPropertyId: metricComponent.name,
                customPropertyType: null,
                objId: null,
                quickHistory: metricComponent.quickHistory,
                checkForUpdatePeriod: metricComponent.checkForUpdatePeriod,
                filterName: metricComponent.filter,
                description: component.description || metric.description,
                filterArg: { metric: metric, templateElement: metricComponent.getTemplateInputMap() }
            };
        } else if (component instanceof PropertyComponent) {
            const propertyComponent = component as PropertyComponent;
            let isFile = false;
            let customPropertyType;
            let objId;
            let propertyDef: CustomPropertyDefinition;
            this.propertySubjects[propertyComponent.name] = subject;
            let unit;
            let propertyInfo;
            if (propertyComponent.name.startsWith('customer.')) {
                const path = propertyComponent.name.substr(9);
                propertyInfo = Properties.Customer[path];
                let node: any = this.element.customer;
                let defaultValue = '';
                let propertyPath = 'customer.properties.';
                if (propertyComponent.name.startsWith(propertyPath)) {
                    customPropertyType = CustomPropertyType.Customer;
                    objId = node?.id;
                    propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, propertyComponent.name.substring(propertyPath.length));
                    defaultValue = propertyDef ? propertyDef.value : '';
                    isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                }
                let value = _.get(node, path, this.getDefaultValue(component, defaultValue || ''));
                if (propertyDef) {
                    value = this.getDictionaryValue(propertyDef, value);
                }
                subject.next(value);
            } else if (propertyComponent.name.startsWith('location.')) {
                const path = propertyComponent.name.substr(9);
                propertyInfo = Properties.Location[path];
                let node: any = this.element.location;
                let defaultValue = propertyComponent.name == 'location.country' || propertyComponent.name == 'location.timezone' ? _.get(node.customer, path, '') : '';
                let propertyPath = 'location.properties.';
                if (propertyComponent.name.startsWith(propertyPath)) {
                    customPropertyType = CustomPropertyType.Location;
                    objId = node?.id;
                    propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, propertyComponent.name.substring(propertyPath.length));
                    defaultValue = propertyDef ? propertyDef.value : '';
                    isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                }
                let value = _.get(node, path, this.getDefaultValue(component, defaultValue || ''));
                if (propertyDef) {
                    value = this.getDictionaryValue(propertyDef, value);
                }
                if (propertyComponent.name.startsWith('location.metrics.')) {
                    let metricName = propertyComponent.name.split('.')[2];
                    let metric = locationMetrics.find(m => m.name == metricName);
                    if (metric) {
                        unit = metric.unit;
                    }
                }
                subject.next(value);
            } else if (propertyComponent.name.startsWith('properties.')) {
                let defaultValue = '';
                let propertyPath = 'properties.';
                customPropertyType = CustomPropertyType.Thing;
                objId = this.element.id;
                propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, propertyComponent.name.substring(propertyPath.length));
                defaultValue = propertyDef ? propertyDef.value : '';
                isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                let value = _.get(this.element, propertyComponent.name, this.getDefaultValue(component, defaultValue || ''));
                value = this.getDictionaryValue(propertyDef, value);
                subject.next(value);
            } else if (propertyComponent.name.startsWith('thingDefinition.')) {
                const path = propertyComponent.name.substr(16);
                propertyInfo = Properties.ThingDefinition[path];
                let propertyPath = 'thingDefinition.properties.';
                let defaultValue = '';
                if (propertyComponent.name.startsWith(propertyPath)) {
                    customPropertyType = CustomPropertyType.ThingDefinition;
                    objId = this.element.thingDefinitionId;
                    propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, propertyComponent.name.substring(propertyPath.length));
                    defaultValue = propertyDef ? propertyDef.value : '';
                    isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                }
                let value = _.get(this.thingDefinition, path, this.getDefaultValue(component, defaultValue || ''));
                if (propertyDef) {
                    value = this.getDictionaryValue(propertyDef, value);
                }
                subject.next(value);
            } else {
                subject.next(isEmpty(this.getThingPropertyValue(propertyComponent.name)) ? this.getDefaultValue(component, null) : this.getThingPropertyValue(propertyComponent.name));
            }
            return {
                name: propertyInfo ? Promise.resolve(propertyComponent.label || propertyInfo.label || propertyComponent.name) : this.getLabel(propertyComponent),
                originalName: propertyInfo ? Promise.resolve(propertyInfo.label || propertyComponent.name) : this.getLabel(propertyComponent, false),
                value: subject.asObservable(),
                filter: this.getFilter(propertyComponent),
                unit: !this.filterService.isUnitAware(propertyComponent.filter as string) ? unit : null,
                showLabel: propertyComponent.showLabel,
                downloadable: isFile,
                metricNameOrPropertyId: propertyDef ? propertyDef.id : null,
                customPropertyType: customPropertyType,
                objId: objId,
                description: propertyComponent.description || propertyDef?.description,
                filterArg: propertyDef ? { property: propertyDef, templateElement: propertyComponent.getTemplateInputMap() } : null
            };
        } else if (component instanceof CompositePartComponent) {
            return {
                name: Promise.resolve(component.label || component.name),
                originalName: Promise.resolve(component.name),
                value: component.get(this.element, CompositePartMode.DETAIL).pipe(map(val => {
                    if (val) {
                        const v = val as Value;
                        return v.value;
                    } else {
                        return this.getDefaultValue(component, null);
                    }
                })),
                filter: component.filter || DefaultCompositePartPipe,
                unit: null,
                showLabel: component.showLabel,
                downloadable: false,
                metricNameOrPropertyId: null,
                customPropertyType: null,
                objId: null,
                description: component.description
            }
        } else if (component instanceof StatisticComponent) {
            const statisticSubject: BehaviorSubject<{ statisticItems: StatisticItem[], filter: string | Function }> = new BehaviorSubject(null);
            if (component.groupBy && component.groupBy.length > 2) {
                component.groupBy = component.groupBy.slice(0, 2);
            }
            if (component.periodRef) {
                component.startDateFieldRef = null;
                component.endDateFieldRef = null;
            }
            this.fieldsNames.push([component.startDateFieldRef, component.endDateFieldRef, component.periodRef]);
            this.fieldServiceSubscriptions.push(this.fieldService.subscribeToFields([component.startDateFieldRef, component.endDateFieldRef, component.periodRef]).subscribe(fieldsMap => {
                this.statisticService.getStatisticValue(component, fieldsMap, this.element).then(value => statisticSubject.next({ statisticItems: this.handleStatisticValue(value, component), filter: component.filter }), err => console.error(err))
            }));
            return {
                name: Promise.resolve(component.label || component.name),
                originalName: Promise.resolve(component.name),
                value: statisticSubject.asObservable(),
                filter: StatisticPipe,
                unit: null,
                showLabel: true,
                downloadable: false,
                metricNameOrPropertyId: null,
                customPropertyType: null,
                objId: null,
                description: component.description
            };
        } else {
            throw new Error('Widget definition error: some components are not valid');
        }
    }

    getThingPropertyValue(propertyName: string): any {
        const prop = this.getThingProperty(propertyName);
        if (prop) {
            const value = this.objUtils.getValue(this.element, prop.path);
            if (isEmpty(value) && prop.inheritedPath) {
                return this.objUtils.getValue(this.element, prop.inheritedPath);
            }
            return value;
        }
    }

    private getThingProperty(propertyName: string): any {
        if (!propertyName) {
            return undefined;
        }
        const prop: any = ThingService.THING_PROPERTY[propertyName];
        if (!prop) {
            console.error(`Invalid thing property '${propertyName}'`);
        }
        return prop;
    }

    private handleStatisticValue(value: any, component: StatisticComponent): any {
        const hasPeriodGroupBy = component.groupBy ? component.groupBy.some(el => StatisticService.PERIOD_GROUP_BY_LIST.find(period => period == el) != null) : false;
        if (value && value.length > 0) {
            return this.statisticService.sortStatisticItems(value, hasPeriodGroupBy, component);
        }
        return null;
    }

    private updateProperties(thingEvent: ThingDataItem[]): void {
        // getting the right property and publishing the value
        if (thingEvent && this.propertySubjects) {
            thingEvent.forEach(thingDataItem => {
                if (PropertyService.getFieldName(thingDataItem) == "simDetails") {
                    const propertyPath = "simDetails.";
                    const simDetailsProperties = Object.keys(this.propertySubjects).filter(key => key.startsWith(propertyPath));
                    simDetailsProperties.forEach(propName => {
                        let subject = this.propertySubjects[propName];
                        this.zone.run(() => subject.next(thingDataItem.value[propName.substring(propertyPath.length)]));
                    });
                } else {
                    let subject = this.propertySubjects[PropertyService.getFieldName(thingDataItem)];
                    if (subject) {
                        this.zone.run(() => subject.next(thingDataItem.value));
                    }
                }
            });
        }
    }

    private getValue(data: Value, isBlob: boolean, defaultValue: string): any {
        if (isBlob) {
            return data;
        }
        return data && data.value !== '' && data.value != undefined ? data.value : defaultValue;
    }

    private getLabel(property: PropertyComponent, resolveLabel: boolean = true): Promise<string> {
        if (property.label && resolveLabel) {
            return Promise.resolve(property.label);
        }
        let propName: string = null;
        let type: CustomPropertyType = null;

        if (property.name.startsWith('customer.properties.')) {
            propName = property.name.substr(20);
            type = CustomPropertyType.Customer;
        } else if (property.name.startsWith('location.properties.')) {
            propName = property.name.substr(20);
            type = CustomPropertyType.Location;
        } else if (property.name.startsWith('thingDefinition.properties.')) {
            propName = property.name.substr(27);
            type = CustomPropertyType.ThingDefinition;
        } else if (property.name.startsWith('properties.')) {
            propName = property.name.substr(11);
            type = CustomPropertyType.Thing;
        }

        if (propName != null && type != null) {
            let definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(type, propName);
            if (definition) {
                if (resolveLabel) {
                    return Promise.resolve(definition.label || definition.name);
                } else {
                    return Promise.resolve(definition.name);
                }                
            } else {
                return Promise.resolve(property.name);
            }
        } else if (property.name.startsWith('customer.') || property.name.startsWith('location.') || property.name.startsWith('thingDefinition.')) {
            return Promise.resolve(property.name);
        } else {
            return Promise.resolve(this.getThingPropertyLabel(property.name))
        }
    }

    private getFilter(property: PropertyComponent): string | Function {
        if (property.name.startsWith('customer.') || property.name.startsWith('location.') || property.name.startsWith('properties.') || property.name.startsWith('thingDefinition.')) {
            return property.filter || this.getDefaultFilter(property);
        } else {
            return property.filter || this.getThingPropertyDefaultFilter(property.name);
        }
    }

    getThingPropertyLabel(propertyName: string): any {
        const prop = this.getThingProperty(propertyName);
        if (prop) {
            return prop.label;
        }
    }

    getThingPropertyDefaultFilter(propertyName: string): any {
        const prop = this.getThingProperty(propertyName);
        if (prop) {
            if (propertyName == 'serviceLevel') {
                return 'defaultServiceLevel';
            } else {
                return prop.defaultFilter;
            }
        }
    }

    private getDefaultFilter(property: PropertyComponent): string | Function {
        const name = property.name;
        if (name.startsWith('customer.')) {
            const propName = name.substr(9);
            if (Properties.Customer[propName]) {
                return Properties.Customer[propName].defaultFilter;
            } else if (propName.startsWith('properties.')) {
                const definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Customer, propName.substr(11));
                if (definition && definition.type === 'CONTACTS') {
                    return DefaultContactsTablePipe;
                }
            }
        } else if (name.startsWith('location.')) {
            const propName = name.substr(9);
            if (Properties.Location[propName]) {
                return Properties.Location[propName].defaultFilter;
            } else if (propName.startsWith('properties.')) {
                const definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Location, propName.substr(11));
                if (definition && definition.type === 'CONTACTS') {
                    return DefaultContactsTablePipe;
                }
            }
        } else if (name.startsWith('thingDefinition.')) {
            const propName = name.substr(16);
            if (Properties.ThingDefinition[propName]) {
                return Properties.ThingDefinition[propName].defaultFilter;
            }
        } else if (name.startsWith('properties.')) {
            const definition = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Thing, name.substr(11));
            if (definition && definition.type === 'CONTACTS') {
                return DefaultContactsTablePipe;
            }
        }
        return null;
    }

    resetMetric(metric: Metric, resetValue: string): Promise<void> {
        return this.dataService.resetMetric(this.element.id, metric, resetValue);
    }

    getDefaultNullValue(): string {
        return this.defaultNullValue;
    }

}