import { FlatTreeControl } from "@angular/cdk/tree";
import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, EventEmitter, forwardRef, HostListener, Inject, Input, OnDestroy, Output } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { firstValueFrom, Subscription } from "rxjs";
import * as svgPanZoom from "svg-pan-zoom";
import { ErrorMessages } from "../../../common/constants";
import { ProductModel, ProductModelTechnicalScheme, ProductModelTechnicalSchemeUrlType } from "../../../model";
import { ExternalCatalogService, SvgClickEventData, SvgClickEventDataType } from "../../../service/external-catalog.service";
import { FlatTreeNode } from "../../../service/flat-tree.service";
import { ProductModelPartService } from "../../../service/product-model-part.service";
import { ProductModelService } from "../../../service/product-model.service";
import { TreeNode } from "../../../shared/component/tree-list/tree-list.component";
import { LoaderPipe } from "../../../shared/pipe";
import { ErrorUtility } from "../../../utility/error-utility";

@Component({
    selector: 'product-model-technical-scheme-selector',
    template: require('./product-model-technical-scheme-selector.component.html'),
    styles: [require('./product-model-technical-scheme-selector.component.css')]
})
export class ProductModelTechnicalSchemeSelectorComponent implements OnDestroy {

    @Input() set productModelId(value: string) {
        this._productModelId = value;
        this.selectedProductModelPartId = null;
        if (value) {
            this.init();
        } else {
            this.loaded = true;
        }
    }

    @Input() productModelPartId: string;

    @Output() productModelPartSelected = new EventEmitter();

    @Output() openSparePartDefinitionDetails = new EventEmitter();

    error: string;
    productModel: ProductModel;
    loaded: boolean;
    _productModelId: string;
    selectedProductModelPartId: string;
    showTree: boolean;
    visibleSchemes: ProductModelTechnicalScheme[];
    svgContainerPrefix: string = 'svg-container-';
    schemesLoaded: boolean;
    svgSchemeContent: { [tabIndex: number]: string } = {};

    private transformer = (node: TreeNode, level: number) => {
        return {
            id: node.id,
            name: node.name,
            level: level,
            expandable: (!!node.children && node.children.length > 0),
            element: node.element
        };
    }
    treeControl = new FlatTreeControl<FlatTreeNode>(
        node => node.level, node => node.expandable);
    treeFlattener = new MatTreeFlattener(
        this.transformer, node => node.level,
        node => node.expandable, node => node.children);
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    private panZoom: SvgPanZoom.Instance;
    private externalCatalogSubscription: Subscription;

    constructor(
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService,
        @Inject(forwardRef(() => ProductModelPartService)) private productModelPartService: ProductModelPartService,
        @Inject(forwardRef(() => HttpClient)) private httpClient: HttpClient,
        @Inject(DOCUMENT) private document: Document,
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => ExternalCatalogService)) private externalCatalogService: ExternalCatalogService,
    ) {
        this.subscribeToExternalCatalogService();
    }

    hasChild = (_: number, node: FlatTreeNode) => node.expandable;

    private init(): void {
        this.loaded = false;
        Promise.all([
            this.productModelService.getById(this._productModelId),
            this.productModelPartService.getRecursivelyAllProductModelParts(0, [], this.getExtraParams())
        ]).then(results => {
            this.productModel = results[0];
            const partialTree = this.productModelPartService.fillTreeNodes(results[1]);
            const newRoot: TreeNode = { id: null, name: this.productModel.name, description: this.productModel.description, children: partialTree, element: results[0] };
            this.dataSource.data = [newRoot];
            this.showTree = partialTree?.length > 0;
            let selectedNode: FlatTreeNode;
            if (this.productModelPartId) {
                const nodeIndex = this.treeControl.dataNodes.findIndex(node => node.id === this.productModelPartId);
                if (nodeIndex > -1) {
                    selectedNode = this.treeControl.dataNodes[nodeIndex];
                    this.selectedProductModelPartId = this.productModelPartId;
                    this.visibleSchemes = selectedNode.element?.technicalSchemes;
                    this.expandAncestors(nodeIndex);
                } else {
                    this.error = "Invalid model part";
                }
            } else {
                this.visibleSchemes = this.productModel.technicalSchemes;
                selectedNode = this.treeControl.dataNodes[0];
                this.treeControl.expand(selectedNode);
            }
            this.updateSelectedProductModelPartId(selectedNode);
            this.loaded = true;
            this.loadSvgSchemes();
        }).catch(err => {
            this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR);
            this.loaded = true;
        });
    }

    private getExtraParams(): HttpParams {
        return new HttpParams().set('productModelId', this._productModelId);
    }

    selectProductModelPart(node: FlatTreeNode): void {
        if (!this.productModelPartId) {
            this.updateSelectedProductModelPartId(node);
        }
    }

    private updateSelectedProductModelPartId(node: FlatTreeNode): void {
        this.selectedProductModelPartId = node.id;
        this.visibleSchemes = node.element?.technicalSchemes;
        this.productModelPartSelected.emit({ id: this.selectedProductModelPartId, hasTechnicalSchemes: this.visibleSchemes?.length > 0 });
        this.loadSvgSchemes();
    }

    resetSelectedProductModelPartId(): void {
        this.selectedProductModelPartId = null;
        this.visibleSchemes = this.productModel.technicalSchemes;
        this.productModelPartSelected.emit({ id: this.selectedProductModelPartId, hasTechnicalSchemes: this.visibleSchemes?.length > 0 });
        this.loadSvgSchemes();
    }

    private expandAncestors(index: number): void {
        for (let i = 0; i < index + 1; i++) {
            this.treeControl.expand(this.treeControl.dataNodes[i])
        }
    }

    private loadSvgSchemes(): void {
        this.destroyPanZoom();
        this.externalCatalogService.removeAllEventListeners();
        this.svgSchemeContent = {};
        if (this.visibleSchemes?.length) {
            this.schemesLoaded = false;
            let promises = [];
            this.visibleSchemes.forEach((scheme, index) => {
                if (scheme.urlType == ProductModelTechnicalSchemeUrlType.SVG && scheme.imageUrl) {
                    promises.push(this.loadSvg(scheme.imageUrl, index));
                }
            });
            Promise.all(promises).then(() => {
                this.schemesLoaded = true;
                this.schemeTabIndexChanged(0);
            });
        }
    }

    private loadSvg(schemeUrl: string, index: number): Promise<any> {
        return firstValueFrom(this.httpClient.get(schemeUrl, { headers: new HttpHeaders().append('Skip-Auth', 'true'), responseType: "text" })).then(text => {
            this.svgSchemeContent[index] = text;
        }).catch(err => this.svgSchemeContent[index] = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
    }

    schemeTabIndexChanged(index: number): void {
        this.destroyPanZoom();
        this.externalCatalogService.removeAllEventListeners();
        setTimeout(() => {
            const scheme = this.visibleSchemes[index];
            if (scheme.urlType == ProductModelTechnicalSchemeUrlType.SVG && this.svgSchemeContent[index]) {
                const container = this.document.getElementById(this.svgContainerPrefix + index);
                container.innerHTML = this.svgSchemeContent[index];
                if (container.getElementsByTagName('svg') && container.getElementsByTagName('svg')[0]) {
                    let svg = container.getElementsByTagName('svg')[0];
                    svg.setAttribute('id', 'svg');
                    svg.setAttribute('class', 'svg-element');
                    this.applySchemeTransformer(svg);
                    this.initPanZoom();
                }
            }
        });
    }

    private initPanZoom(): void {
        const svg = this.document.getElementById('svg');
        this.panZoom = svgPanZoom(svg, {
            panEnabled: true,
            controlIconsEnabled: true,
            zoomEnabled: true,
            mouseWheelZoomEnabled: true,
            zoomScaleSensitivity: 0.6,
            fit: true,
            center: true
        });
    }

    private destroyPanZoom(): void {
        this.panZoom?.destroy();
        this.panZoom = null;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.panZoom) {
            this.panZoom.resize();
            this.panZoom.fit();
            this.panZoom.center();
        }
    }

    private applySchemeTransformer(svg: SVGSVGElement): void {
        if (this.loaderPipe.isCustomFilter('svgProductSchemaTransformer')) {
            this.loaderPipe.transform($(svg), 'svgProductSchemaTransformer', null, { "productModelId": this._productModelId, "productModelPartId": this.selectedProductModelPartId });
        } else {
            const svgElements = svg.getElementsByTagName('text');
            Array.from(svgElements).forEach(el => {
                this.externalCatalogService.addSvgClickableElement(el, { "technicalSchemePosition": el.textContent, "productModelId": this._productModelId, "productModelPartId": this.selectedProductModelPartId, "clickableClass": null })
            });
        };
    }

    private subscribeToExternalCatalogService(): void {
        this.externalCatalogSubscription = this.externalCatalogService.getSvgClickEventSubject().subscribe(event => {
            this.handleSvgEvent(event);
        });
    }

    private handleSvgEvent(event: SvgClickEventData): void {
        switch (event.eventType) {
            case SvgClickEventDataType.CHANGE_PRODUCT_MODEL_PART:
                const nodeIndex = this.treeControl.dataNodes.findIndex(node => node.id === event.elementId);
                if (nodeIndex > -1 && !this.productModelPartId) {
                    const selectedNode = this.treeControl.dataNodes[nodeIndex];
                    this.expandAncestors(nodeIndex);
                    this.updateSelectedProductModelPartId(selectedNode);
                }
                break;
            case SvgClickEventDataType.OPEN_SPARE_PART_DEFINITION_DETAILS:
                this.openSparePartDefinitionDetails.emit(event.elementId);
                break;
        }
    }

    ngOnDestroy(): void {
        this.destroyPanZoom();
        this.externalCatalogService.removeAllEventListeners();
        if (this.externalCatalogSubscription) {
            this.externalCatalogSubscription.unsubscribe();
            this.externalCatalogSubscription = null;
        }
    }

}