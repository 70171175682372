import { Component, forwardRef, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'expandable-widget-dialog',
    template: `
        <h2 mat-dialog-title class="d-flex align-items-center">
            <span *ngIf="title">{{ title | localize }}</span>
            <button mat-icon-button mat-dialog-close class="ml-auto">
                <mat-icon>close</mat-icon>
            </button>
        </h2>
        <mat-dialog-content>
            <div style="margin-top: 5px" class="borderless-widget">
                <include-template [name]="templateName"></include-template>
            </div>
        </mat-dialog-content>
    `,
})
export class ExpandableWidgetDialogComponent {

    title: string;
    templateName: string;

    constructor(
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ExpandableWidgetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.title = data.title;
        this.templateName = data.templateName;
    }
}