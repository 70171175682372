import { HttpParams } from '@angular/common/http';
import { Component, ContentChildren, forwardRef, Host, Inject, Input, NgZone, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ErrorMessages, Permissions } from '../../common/constants';
import { SOCKET_TOPIC_THING_LIST } from '../../common/endpoints';
import { PropertyInfo } from '../../common/properties';
import { CustomMatPaginatorIntl } from '../../dashboard-area/shared/custom-mat-paginator-intl.service';
import { BulkUpdateParentThingsResponse, Location, Tag, Thing, ThingDataItem } from '../../model';
import { BulkUpdateType } from '../../model/bulk-update';
import { BulkUpdateResourcesResponseItem } from '../../model/bulk-update-resource-response-item';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { AddButtonContextService, AddButtonResource } from '../../service/add-button-context.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomLabelService } from '../../service/custom-label.service';
import { NavigationService } from '../../service/navigation.service';
import { PropertyService } from '../../service/property.service';
import { RefreshableWidget, RefresherWidgetService } from '../../service/refresher-widget.service';
import { UserThingService } from '../../service/user-thing.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { CompositePartComponent, MessageComponent, MetricDetailComponent, ModalComponent, PropertyComponent } from '../../shared/component';
import { BulkOperationDialogComponent, BulkOperationDialogData } from '../../shared/component/bulk-operation-dialog/bulk-operation-dialog.component';
import { BulkOperationSelectionDialogComponent, BulkOperationSelectionDialogData } from '../../shared/component/bulk-operation-selection-dialog/bulk-operation-selection-dialog.component';
import { BulkParentAssignDialogComponent, BulkParentAssignDialogData } from '../../shared/component/bulk-parent-assign-dialog/bulk-parent-assign-dialog.component';
import { BulkUpdateTagDialogComponent, BulkUpdateTagDialogData } from '../../shared/component/bulk-update-tag-dialog/bulk-update-tag-dialog.component';
import { SearchTargetType } from '../../shared/component/search-field/search-field.component';
import { BulkDeleteDialogData, ThingBulkDeleteDialogComponent } from '../../shared/component/thing-bulk-delete-dialog/thing-bulk-delete-dialog.component';
import { ButtonActionValue, CustomTableComponent, CustomTableService } from '../../shared/custom-table';
import { LocalizationPipe } from '../../shared/pipe';
import { TagService } from '../../shared/tags/tag.service';
import { COMPONENT_DEFINITION_REF } from '../../shared/utility/component-definition-token';
import { ErrorUtility } from '../../utility/error-utility';
import { AdvancedSearchLayoutType, BulkControl, ListWidgetV2Component } from '../list-widget-v2/list-widget-v2.components';
import { ThingListWidgetV2Service } from './thing-list-widget-v2.service';

@Component({
    selector: 'thing-list-widget-v2',
    template: require('./thing-list-widget-v2.component.html'),
    styles: [require('../list-widget-v2/list-widget-v2.css')],
    providers: [ThingListWidgetV2Service, TagService, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class ThingListWidgetV2Component extends ListWidgetV2Component<Thing> implements OnInit, OnDestroy, RefreshableWidget {

    @Input() includeUnassigned: boolean;

    @Input() includeAssigned: boolean = true;

    @Input() enableActions = true;

    @Input() searchFields: string[] = ["name", "serialNumber", "customer.name", "customer.code"];

    @Input() bulkControlsEnabled: boolean;

    @Input() hideSearch: boolean;

    @Input() tagDeleteEnabled: boolean;

    @Input() expandableCompositeThings: boolean;

    @Input() showRootThings: boolean;

    @Input() detailsPath: string;

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<MetricDetailComponent | CompositePartComponent | PropertyComponent>;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    @ViewChild('confirmDeleteTag') confirmDeleteTag: ModalComponent;

    @ViewChild(CustomTableComponent) customTable: CustomTableComponent;

    things: Thing[];
    location: Location;
    currentThing: Thing;
    message: string;
    bulkControls: BulkControl[] = [];
    elementExpandableCondition: { path: string, condition: string, value?: any } = { path: 'thingCount', condition: 'GREATER', value: 0 };

    private thingsSubjects: { [thingId: string]: BehaviorSubject<ThingDataItem[]> } = {};
    private metricNames = new Set<string>();
    private unregisterFn: Function;
    private deletingTagThingId: string;
    private tags: Tag[];

    constructor(
        @Inject(forwardRef(() => ThingListWidgetV2Service)) private thingListWidgetService: ThingListWidgetV2Service,
        @Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => PropertyService)) private propertyService: PropertyService,
        @Inject(forwardRef(() => NgZone)) private zone: NgZone,
        @Inject(forwardRef(() => RefresherWidgetService)) private refresherWidgetService: RefresherWidgetService,
        @Inject(forwardRef(() => TagService)) private tagService: TagService,
        @Inject(forwardRef(() => UserThingService)) private userThingService: UserThingService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => AddButtonContextService)) addButtonService: AddButtonContextService
    ) {
        super(appService, authenticationService, exportService, addButtonService);
    }

    private defaultProperties: { [name: string]: PropertyInfo } = {
        name: { label: 'thingNameProperty', path: 'name', defaultFilter: null, defaultSorting: null },
        serialNumber: { label: 'serialNumberProperty', path: 'serialNumber', defaultFilter: null, defaultSorting: null },
        gpsPosition: { label: 'gpsPositionProperty', path: 'gpsPosition', defaultFilter: null, defaultSorting: null },
        serviceLevel: { label: 'serviceLevelProperty', path: 'serviceLevel', defaultFilter: 'defaultServiceLevel', defaultSorting: 'service-level-sorting' },
        "customer.name": { label: 'customerNameProperty', path: 'customer.name', defaultFilter: null, defaultSorting: null },
        "location.name": { label: 'locationNameProperty', path: 'location.name', defaultFilter: null, defaultSorting: null }
    };

    ngOnInit(): void {
        this.checkIsMobile();
        this.readPermission = this.authenticationService.hasPermission(Permissions.READ_THING) || this.authenticationService.isGuestUser();
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_THING);
        this.location = this.contextService.getCurrentLocation();
        this.unregisterFn = this.refresherWidgetService.register(this);
        this.currentThing = this.thingContextService.getCurrentThing();
        this.showAddButton = this.writePermission && this.enableActions && (this.location || this.authenticationService.getUser().location) && this.includeAssigned && !this.currentThing;
        this.setBulkControls();
        this.handleAdvancedSearchLayoutType("thing-list-popup-advanced-search", SearchTargetType.THINGS);
        if (this.exportEnabled) {
            this.subscribeToExportServices();
        }
        this.preserveSelectedBetweenPages = true;
        if (this.showAddButton) {
            this.subscribeToAddButtonVisibility(AddButtonResource.THING);
        }
    }

    private setBulkControls(): void {
        this.bulkControls = [
            { icon: "sell", iconClass: "material-icons-outlined", action: 'EDIT_TAGS', title: 'updateTagsProperty', visible: this.authenticationService.hasPermission(Permissions.WRITE_THING_TAG) },
            { icon: "magic_exchange", iconClass: "material-symbols-outlined", action: 'BULK_OPERATION_SELECTION', title: 'bulkActionsProperty', visible: true },
            { icon: "delete", iconClass: "material-symbols-outlined", action: 'BULK_DELETE', title: 'bulkDeleteProperty', visible: this.authenticationService.hasPermission(Permissions.DELETE_THING) }
        ];
        this.bulkControlsEnabled = this.bulkControlsEnabled && this.bulkControls.some(control => control.visible);
    }

    ngAfterContentInit(): void {
        this.tags = this.contextService.getTagObjects();
        this.displayedColumns = this.thingListWidgetService.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'Thing');
        if (this.tagDeleteEnabled && this.query?.length && 'tags' == this.query[0].property) {
            this.displayedColumns.push(CustomTableService.newButtonColumn('deleteTag', '', 'id', 'float-right', 'removeButton').withMatIcon('label_off'))
        }
        this.descriptions = this.thingListWidgetService.getColumnDescriptions(this.columnComponents.toArray());
        this.metricNames = this.thingListWidgetService.getMetricNames(this.columnComponents);
        if (!this.sort) {
            this.sort = this.thingListWidgetService.setDefaultSort(this.displayedColumns);
        }
        const storedFieldsValues = localStorage.getItem(this.queryFieldRef || 'thingAdvancedSearchFieldsValues');
        const savedFieldsValues = storedFieldsValues ? JSON.parse(storedFieldsValues) : null;
        if (!savedFieldsValues && !this.query && !this.queryFieldRef && this.advancedSearchLayout != AdvancedSearchLayoutType.POPUP) {
            this.getThingList();
        }
    }

    private getThingList(): void {
        this.thingListWidgetService.getPagedList(this.pageIndex, this.pageSize, this.sort, this.metricNames, this.searchFields, this.advancedSearchBody, this.location, this.includeAssigned, this.includeUnassigned, this.currentThing, this.showRootThings).then(pagedList => {
            this.things = pagedList.content;
            this.error = null;
            this.subscribeToThings();
            this.thingListWidgetService.addTags(pagedList.content, this.tags || []);
            this.updateElementList(pagedList);
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    private subscribeToThings(): void {
        for (let thing of this.things) {
            let thingSubject = this.propertyService.subscribeToThingProperties(thing.id);
            this.thingsSubjects[thing.id] = thingSubject;
            thingSubject.subscribe((thingEvent: ThingDataItem[]) => this.updateProperties(thing.id, thingEvent));
        }
    }

    private updateProperties(thingId: string, thingEvent: ThingDataItem[]): void {
        if (thingEvent) {
            let thing = this.things.find(t => t.id == thingId);
            for (let thingDataItem of thingEvent) {
                _.set(thing, PropertyService.getFieldName(thingDataItem), thingDataItem.value);
            }
            this.zone.run(() => this.dataSource.data = this.things);
        }
    }

    addThing(): void {
        if (this.authenticationService.isLocationUser()) {
            this.navigationService.navigateTo(['/dashboard/thing_details/add']);
        } else {
            this.navigationService.navigateTo(['/dashboard/location_details', this.location.id, 'thing_details', 'add']);
        }
    }

    goToDetail(thing: Thing): void {
        this.navigationService.goToThingDetailPage(thing?.id, this.detailsPath);
    }

    ngOnDestroy(): void {
        if (this.unregisterFn) {
            this.unregisterFn();
        }
        this.unsubscribeAll();
        this.unsubscribeFormExport();
        this.unsubscribeFromAddButtonVisibility();
    }

    private unsubscribeAll(): void {
        for (let thingId in this.thingsSubjects) {
            this.propertyService.usubscribeFromThingProperties(thingId);
        }
        this.thingsSubjects = {};
    }

    getRefreshTopic(): string {
        let userId = this.authenticationService.getUser().id;
        return SOCKET_TOPIC_THING_LIST.replace('{userId}', userId);
    }

    refresh(): void {
        setTimeout(() => {
            this.zone.run(() => {
                this.loaded = false;
                this.unsubscribeAll();
                this.getThingList();
            });
        }, 1000);
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.unsubscribeAll();
        this.getThingList();
    }

    export(): void {
        const params = this.thingListWidgetService.getParams(this.metricNames, this.searchFields, this.advancedSearchBody, this.location, this.includeAssigned, this.includeUnassigned, this.currentThing, true);
        this.thingListWidgetService.downloadCSV(params, this.exportService.resolveExportFileNamePlaceholders(this.exportFileName));
    }

    openBulkControl(action: string): void {
        const params = this.thingListWidgetService.getParams(this.metricNames, this.searchFields, this.advancedSearchBody, this.location, this.includeAssigned, this.includeUnassigned, this.currentThing, false);
        switch (action) {
            case 'EDIT_TAGS':
                this.openBulkUpdateTagDialog(params);
                break;
            case 'BULK_OPERATION_SELECTION':
                this.openBulkOperationSelectionDialog(params);
                break;
            case 'BULK_DELETE':
                this.openBulkDeleteDialog(params);
                break;
            default:
                return null;
        }
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        if (actionValue.action == 'deleteTag') {
            this.deletingTagThingId = actionValue.value;
            this.confirmDeleteTag.show();
        }
    }

    deleteTag(): void {
        this.confirmDeleteTag.hide();
        let tagName = this.query[0].value;
        const tagId = this.tags.find(t => t.name == tagName).id;
        this.tagService.removeTagsOnThing(this.deletingTagThingId, [tagId]).then(() => this.refreshElementList());
    }

    cancelDeleteTag(): void {
        this.confirmDeleteTag.hide();
    }

    updateDetails(action: { status: string, index: number }): void {
        if (this.expandableCompositeThings) {
            this.unsubscribeAll();
            if (action.status == 'open') {
                this.userThingService.getRecursivelyAllThings(0, [], null, this.things[action.index].id).then(things => {
                    let arrowStatusesToAdd: string[] = [];
                    things.forEach(t => {
                        t['rowLevel'] = this.things[action.index]['rowLevel'] ? this.things[action.index]['rowLevel'] + 1 : 1;
                        arrowStatusesToAdd.push("closed");
                    });
                    this.things.splice(action.index + 1, 0, ...things);
                    this.elements = _.cloneDeep(this.things);
                    this.dataSource = new MatTableDataSource<Thing>(this.elements);
                    this.error = null;
                    this.subscribeToThings();
                    this.customTable.updateArrowStatuses(action.index + 1, 0, arrowStatusesToAdd);
                }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
            } else {
                let parentIds: string[] = [this.things[action.index].id];
                let i = action.index + 1;
                let elementsToRemove: number = 0;
                while (i < this.things.length && parentIds.includes(this.things[i].parentThingId) && this.things[i]['rowLevel'] != null) {
                    parentIds.push(this.things[i].id);
                    elementsToRemove++;
                    i++;
                }
                this.things.splice(action.index + 1, elementsToRemove);
                this.elements = _.cloneDeep(this.things);
                this.dataSource = new MatTableDataSource<Thing>(this.elements);
                this.subscribeToThings();
                this.customTable.updateArrowStatuses(action.index + 1, elementsToRemove, []);
            }
        }
    }

    protected subscribeToExportServices(): void {
        this.exportId = "thing-list-" + this.getNextId();
        this.exportService.subscribeToExport(this.exportId, this.title || "Things").subscribe(() => this.export());
        this.subscribeToExportVisibility();
    }

    private openBulkOperationDialog(operation: BulkUpdateType, searchParams: any, bulkUpdateItems: BulkUpdateResourcesResponseItem[]): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        const data: BulkOperationDialogData = {
            operationType: operation,
            selectedThingIds: this.selectedElements.map(el => { return el["id"]; }),
            allElementsSelected: this.allElementsSelected,
            searchParams: searchParams,
            areaCoordinates: null,
            selectedCoordinates: null,
            mapTagging: false,
            bulkUpdateItems: bulkUpdateItems
        }
        dialogConfig.data = data;
        firstValueFrom(this.dialog.open(BulkOperationDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                if (result == 'NOW') {
                    this.showSnackbar('bulkOperationExecutedProperty');
                } else {
                    this.showSnackbar('bulkOperationScheduledProperty')
                }
            }
        });
    }

    private openBulkUpdateTagDialog(searchParams: any): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        const data: BulkUpdateTagDialogData = {
            selectedThingIds: this.selectedElements.map(el => { return el["id"]; }),
            allElementsSelected: this.allElementsSelected,
            searchParams: searchParams,
            areaCoordinates: null,
            selectedCoordinates: null,
            mapTagging: false
        }
        dialogConfig.data = data;
        firstValueFrom(this.dialog.open(BulkUpdateTagDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.tags = this.contextService.getTagObjects();
                this.refreshElementList();
            }
        });
    }

    private openBulkOperationSelectionDialog(searchParams: HttpParams): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        const data: BulkOperationSelectionDialogData = {
            selectedThingIds: this.selectedElements.map(el => { return el["id"]; }),
            allElementsSelected: this.allElementsSelected,
            searchParams: searchParams
        }
        dialogConfig.data = data;
        firstValueFrom(this.dialog.open(BulkOperationSelectionDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                if (result.operation == 'CHANGE_PARENT_THING') {
                    this.openBulkParentAssign(searchParams, result.bulkOperationItems);
                } else {
                    this.openBulkOperationDialog(result.operation, searchParams, result.bulkOperationItems);
                }
            }
        });
    }

    private openBulkParentAssign(searchParams: HttpParams, bulkUpdateParentThingsResponse: BulkUpdateParentThingsResponse): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        const data: BulkParentAssignDialogData = {
            searchParams: searchParams,
            areaCoordinates: null,
            selectedCoordinates: null,
            mapTagging: false,
            allElementsSelected: this.allElementsSelected,
            bulkUpdateParentThingItem: bulkUpdateParentThingsResponse,
            selectedThingIds: this.selectedElements.map(el => { return el["id"]; })
        }
        dialogConfig.data = data;
        firstValueFrom(this.dialog.open(BulkParentAssignDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.showSnackbar('updateParentThingMessage');
                this.refreshElementList();
            }
        });
    }

    private openBulkDeleteDialog(searchParams: any): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        const data: BulkDeleteDialogData = {
            selectedThingIds: this.selectedElements.map(el => { return el["id"]; }),
            allElementsSelected: this.allElementsSelected,
            searchParams: searchParams,
            elementCount: this.allElementsSelected ? this.length : this.selectedElements.length
        }
        dialogConfig.data = data;
        firstValueFrom(this.dialog.open(ThingBulkDeleteDialogComponent, dialogConfig).afterClosed()).then(result => {
            if (result) {
                this.showSnackbar('bulkDeleteExecutedProperty');
                this.refreshElementList();
            }
        });
    }

    private showSnackbar(text: string): void {
        this.labelService.getCustomLabel(text)
            .then(message => {
                this.snackBar.open(this.localizationPipe.transform(message), '', {
                    duration: 5000,
                    panelClass: 'notification-info'
                });
            });
    }

}

