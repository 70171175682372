import { Contact, Customer, Device, HostCustomer, Location, Organization, Partner, Tenant, UserPermissions } from './index';
import { AuthorizationPolicy } from './user-thing-authorization';

export interface User {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	timezone: string;
	language?: string;
	locale?: string;
	tenant?: Tenant;
	organization?: Organization;
	organizationId?: string;
	customer?: Customer;
	customerId?: string;
	location?: Location;
	locationId?: string;
	partner?: Partner;
	partnerId?: string;
	permissions: string[];
	userPermissions: UserPermissions;
	userTypeName: string;
	properties?: { [name: string]: string | number | Contact[] };
	customerVerifier?: boolean;
	termsOfServiceAcceptedTimestamp?: number;
	privacyPolicyAcceptedTimestamp?: number;
	userAgreementDocumentAcceptedTimestamps?: { [docName: string]: number };
	temporary?: boolean;
	firstAccessTimestamp?: number;
	lastAccessTimestamp?: number;
	defaultAuthorization?: AuthorizationPolicy;
	activityState?: UserActivityState;
	hostCustomers?: HostCustomer[];
	confirmPersonalData?: boolean;
	disableAllNotifications?: boolean;
	notificationExceptionsByLocation?: string[];
	accountCreationEmailSendingTimestamp?: number;
	mfaState?: MfaState;
	thingsSearchFields?: string[];
	userTypeId?: string;
	alertSmsDisabled?: boolean;
	customersSearchFields?: string[];
	thingPeriodicReportsEmailEnabled?: Boolean;
	devices: Device[];
	settings: UserProfileSettings;
	identityProvider: IdentityProviderType;
	alertsSearchFields?: string[];
	workSessionsSearchFields?: string[];
	consoleTemporary?: boolean;
	entity?: Entity;
	userTypeCategory?: string;
	orderNotificationsEnabled?: boolean;
	partnersSearchFields?: string[];
}

export enum UserNodeType {
	ORGANIZATION,
	CUSTOMER,
	LOCATION,
	PARTNER,
	UNDEFINED
}

export enum MfaState {
	ENABLED = 'ENABLED',
	DISABLED = 'DISABLED',
	PRE_ENABLED = "PRE_ENABLED"
}

export enum UserActivityState {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	SUSPENDED = 'SUSPENDED',
	REACTIVATED = 'REACTIVATED'
}

export class UserProfileSettings {
	smsEnabled: boolean;
	voiceEnabled: boolean;
	workSessionEnabled: boolean;
	reportEnabled: boolean;
	mobileNumber: string;
	voiceNotificationFrom: string;
	voiceNotificationTo: string;
	salesforceMfaStatus: SalesforceMfaStatus;
	pushEnabled: boolean;
}

export enum IdentityProviderType {
	APPLICATION = "APPLICATION",
	PING_ONE = "PING_ONE",
	SALESFORCE = "SALESFORCE"
}

export class SalesforceMfaStatus {
	enabled: boolean;
	error: string;
}

export class Entity {
	name: string;
	customer: Customer;
	properties?: { [name: string]: string | number | Contact[] };
}