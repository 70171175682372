import { Component, forwardRef, Inject, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ErrorMessages } from "../../common/constants";
import { AlertDefinition, Thing } from "../../model";
import { ErrorUtility } from "../../utility/error-utility";
import { ManualEventReportDialogService } from "./manual-event-report-dialog.service";

@Component({
    selector: 'manual-event-report-dialog',
    template: require('./manual-event-report-dialog.component.html'),
    providers: [ManualEventReportDialogService],
    styles: [`
        label.required:after {
            content:" *";
        }

        .manual-event-report-message {
            font-size: 12px;
            display: inline-block;
            line-heigth: 125%;
        }
    `]
})
export class ManualEventReportDialogComponent {

    @ViewChild('eventForm') eventForm: NgForm;

    error: string;
    alertDefinitions: AlertDefinition[];
    selectedAlertDefinition: AlertDefinition;
    updating: boolean;

    private thing: Thing;

    constructor(
        @Inject(forwardRef(() => ManualEventReportDialogService)) private manualEventReportDialogService: ManualEventReportDialogService,
        @Inject(forwardRef(() => MatDialogRef)) public dialogRef: MatDialogRef<ManualEventReportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        this.alertDefinitions = data.alertDefinitions;
        this.thing = data.thing;
    }

    alertDefinitionChanged(id: string): void {
        this.selectedAlertDefinition = this.alertDefinitions.find(ad => ad.id == id);
    }

    save(): void {
        const rawValues = this.eventForm.form.getRawValue();
        if (this.thing && rawValues.alertDefinitionId) {
            this.updating = true;
            this.manualEventReportDialogService.report(this.thing, rawValues.alertDefinitionId, rawValues.notes).then(() => {
                this.dialogRef.close(true);
            }).catch(err => {
                this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR);
                this.updating = false;
            });
        } else {
            this.error = "Missing thing or alertDefinition";
        }
    }

}
