import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ErrorMessages, IOT_CONNECTOR_TYPE, Permissions, StateTypes } from '../../common/constants';
import { Thing } from '../../model';
import { ThingConnectionToken } from '../../model/thing-connection-token';
import { AuthenticationService } from '../../service/authentication.service';
import { MessageComponent } from '../../shared/component/index';
import { ConnectionTokensService } from './connection-tokens.service';

@Component({
    selector: 'connection-tokens-edit',
    template: require('./connection-tokens-edit.component.html'),
})
export class ConnectionTokensEditComponent implements OnInit {
    error: string = null;
    state: string = StateTypes.LOADING;
    writePermission: boolean;
    thingConnectionToken: ThingConnectionToken;
    passwordFieldType = 'password';
    payloadFormats: { value: string, label: string }[];
    connectionMappingTypes: { value: string, label: string }[];
    currentConnectorType: string;
    isAssociated: boolean;
    thing: Thing;
    cancelCalled: Function;
    passwordVisible: string = 'password';

    @ViewChild('editForm') form: NgForm;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    @Input() connectionTokenId: string;

    @Output() cancelAction: EventEmitter<void> = new EventEmitter();

    @Output() deleteAction: EventEmitter<void> = new EventEmitter();

    constructor(
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => ConnectionTokensService)) private thingConnectionTokenService: ConnectionTokensService
    ) { }

    ngOnInit(): void {
        this.connectionMappingTypes = Object.keys(IOT_CONNECTOR_TYPE).filter(k => k == "EDC" || k == "SEMIOTY_THING_CONNECTOR" || "HTTP_CONNECTOR").map(k => IOT_CONNECTOR_TYPE[k]);
        this.payloadFormats = this.thingConnectionTokenService.initPayloadFormat();
        this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_THING_CONNECTION_TOKENS && Permissions.READ_THING);
        this.cancelCalled = this.onCancel.bind(this);
        this.refresh(this.connectionTokenId);
    }

    refresh(id: string): void {
        this.thingConnectionTokenService.getThingConnectionTokensById(id).then(result => {
            this.error = null;
            this.state = StateTypes.LOADED;
            this.thingConnectionToken = result;
            this.getMappingType(result.connectionMappingType);
            this.thingConnectionTokenService.getThingByConnectionToken(result.token).then(res => {
                this.isAssociated = true;
                this.thing = res;
            }).catch(err => {
                if (err.status == "404") {
                    this.isAssociated = false;
                } else {
                    this.isAssociated = true;
                    this.writePermission = false;
                    console.error(err);
                }
            });
        }).catch(err => {
            console.error(err);
            this.error = ErrorMessages.GET_DATA_ERROR;
            this.state = StateTypes.ERROR;
        });
    }

    onCancel() {
        this.cancelAction.emit()
    }

    onDelete() {
        this.deleteAction.emit()
    }

    saveThingConnectionToken(): void {
        const formValues = this.form.value;
        const body = {
            token: formValues.token,
            username: formValues.username,
            password: formValues.password,
            connectionMappingType: this.thingConnectionToken.connectionMappingType,
            filename: this.thingConnectionToken.filename,
            description: formValues.description,
            assetId: formValues.assetId,
            path: formValues.path,
            timestamp: this.thingConnectionToken.timestamp,
            serialNumber: formValues.serialNumber,
            simIccid: formValues.simIccid
        };
        if (this.thingConnectionToken.connectionMappingType == 'SEMIOTY_THING_CONNECTOR') {
            if (this.payloadFormats.length > 1) {
                body["payloadFormat"] = formValues.payloadFormat;
            } else {
                body["payloadFormat"] = 'JSON';
            }
        } else if (this.thingConnectionToken.connectionMappingType == 'EDC') {
            body["baseRestUrl"] = formValues.baseRestUrl;
            body["mqttBrokerUrl"] = formValues.mqttBrokerUrl;
            body["accountName"] = formValues.accountName;
        }
        this.thingConnectionTokenService.saveThingConnectionToken(body, "false", this.thingConnectionToken.id)
            .then(() => {
                this.error = null;
                this.saveMessage.show();
                this.refresh(this.thingConnectionToken.id);
            }).catch(err => {
                console.error(err);
                this.error = err.error.message;
            });
    }

    getMappingType(type: string): void {
        switch (type) {
            case IOT_CONNECTOR_TYPE.EDC.value:
                this.currentConnectorType = IOT_CONNECTOR_TYPE.EDC.label;
                break;
            case IOT_CONNECTOR_TYPE.SEMIOTY_THING_CONNECTOR.value:
                this.currentConnectorType = IOT_CONNECTOR_TYPE.SEMIOTY_THING_CONNECTOR.label;
                break;
            case IOT_CONNECTOR_TYPE.HTTP_CONNECTOR.value:
                this.currentConnectorType = IOT_CONNECTOR_TYPE.HTTP_CONNECTOR.label;
                break;
            default:
                this.currentConnectorType = null;
                break;
        }
    }

    getThingProperties(): string {
        return this.thing.name + "-" + this.thing.serialNumber + "  (id: " + this.thing.id + ")";
    }

    setSerialNumber(value: string): void {
        this.form.controls['serialNumber'].setValue(value);
    }

    setToken(value: string): void {
        this.form.controls['token'].setValue(value);
    }

    showPassword() {
        this.passwordVisible = this.passwordVisible == 'password' ? 'text' : 'password';
    }
}