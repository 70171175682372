import { Directive, EventEmitter, forwardRef, Inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomPropertyDefinition } from '../../../model';
import { FieldService } from '../../../service/field.service';
import { FormEditorComponent } from '../../../shared/form-editor/form-editor.component';
import { DatetimeHelper } from '../../../shared/utility/datetime-helper';
import { LocalizationPipe } from '../../pipe';
import { SearchTargetType } from '../search-field/search-field.component';
import { AdvancedSearchDynamicInputsComponent } from './advanced-search-dynamic-inputs.component';
import { SimpleSearchComponent } from './simple-search/simple-search.component';

@Directive()
export abstract class AbstractAdvancedSearchComponent implements OnDestroy {

    @Input() showExport: boolean;

    @Input() showActions: boolean;

    @Input() query: { property: string, predicate: string, value: any }[];

    @Input() enableImport: boolean;

    @Input() importTitle: string;

    @Input() csvExample: string;

    @Input() controlsEnabled: boolean = true;

    @Input() alwaysExpanded: boolean;

    @Input() useExternalAddPropertiesDialog: boolean;

    @Input() maxOneFieldPerRow: boolean;

    @Input() queryId: string;

    @Input() queryFieldRef: string;

    @Input() popupSearchTarget: SearchTargetType;

    @Input() searchInputPropertyFields: string[];

    @Input() simpleSearchFullWidth: boolean;

    @Input() rangeSelectionEnabled: boolean;

    @Output() updateData = new EventEmitter();

    @Output() export = new EventEmitter();

    @Output() import = new EventEmitter();

    @Output() toggleAdvancedSearchAction = new EventEmitter();

    @Output() openExternalAddPropertiesDialogAction = new EventEmitter();

    @Output() advancedSearchAction = new EventEmitter();

    @Output() clearAllAction = new EventEmitter();

    @Output() simpleSearchActionEvent = new EventEmitter();

    @ViewChild('advancedSearchEditor') protected advancedSearchEditor: FormEditorComponent;

    @ViewChild('advancedSearchBarEditor') protected advancedSearchBarEditor: FormEditorComponent;

    @ViewChild(AdvancedSearchDynamicInputsComponent) protected propertiesInputs: AdvancedSearchDynamicInputsComponent;

    @ViewChild(SimpleSearchComponent) protected simpleSearch: SimpleSearchComponent;

    properties: CustomPropertyDefinition[];
    customerProperties: CustomPropertyDefinition[];
    locationProperties: CustomPropertyDefinition[];
    showAdvancedSearch: boolean = false;
    savedFieldsValues: any;
    searchFields: string[];
    advancedSearchAddibleProperties: { name: string, label: string }[];
    encodedQueryFields: any = null;
    simpleSearchKey: any;
    dynamicInputsLoaded: boolean = true;
    fieldsPerRow: number;
    defaultSearchFields: string[] = [];
    advancedSearchConfiguration: any[];
    advancedSearchBarConfiguration: any[];

    protected localStorageKey: string;
    protected fieldServiceSubscription: Subscription;

    protected abstract advancedSearch($event?): void;
    protected abstract getEncodedQueryFields(): void;
    protected abstract initConfigurations(): Promise<void>;

    private isAdvancedSearchInitialized: boolean;

    constructor(
        @Inject(forwardRef(() => LocalizationPipe)) protected localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => FieldService)) protected fieldService: FieldService
    ) { }

    ngOnDestroy(): void {
        if (this.queryFieldRef) {
            this.fieldService.unsubscribeFromFields([this.queryFieldRef]);
        }
        if (this.fieldServiceSubscription) {
            this.fieldServiceSubscription.unsubscribe();
        }
    }

    showHideAdvancedSearch(): Promise<void> {
        if (this.showAdvancedSearch) {
            this.showAdvancedSearch = false;
            this.resetInitialVisibleProperties();
            this.emitAdvancedSearchToggle();
        } else {
            this.showAdvancedSearch = true;
            this.emitAdvancedSearchToggle();
            if (this.simpleSearch) {
                this.simpleSearch.reset();
            }
            if (!this.isAdvancedSearchInitialized) {
                this.isAdvancedSearchInitialized = true;
                return this.initConfigurations().then(() => {
                    this.showAdvancedSearch = true;
                });
            }
        }
        return Promise.resolve();
    }

    private emitAdvancedSearchToggle(): void {
        this.toggleAdvancedSearchAction.emit(this.showAdvancedSearch);
    }

    search(body: any): void {
        if (this.localStorageKey) {
            localStorage.setItem(this.localStorageKey, null);
        }
        this.loadData(body.key);
    }

    protected loadData(key?: string, fields?: any): void {
        const body = Object.assign({}, fields, { key: key || null });
        this.updateData.emit(body);
    }

    clearAll($event?) {
        this.simpleSearchKey = null;
        if (this.localStorageKey) {
            localStorage.setItem(this.localStorageKey, null);
        }
        this.savedFieldsValues = null;
        this.initConfigurations();
        this.advancedSearchEditor.reset();
        if (this.advancedSearchBarEditor) {
            this.advancedSearchBarEditor.reset();
        }
        if (this.propertiesInputs) {
            this.propertiesInputs.reset();
        }
        this.loadData(null, this.encodedQueryFields);
        if ($event) {
            const eventObject = $event.currentTarget;
            eventObject.blur();
        }
    }

    updateSearchFields(updatedSearchFields: string[]): void {
        this.dynamicInputsLoaded = false;
        this.searchFields = this.defaultSearchFields.concat(updatedSearchFields);
        this.searchInputPropertyFields?.forEach(input => {
            if (!this.searchFields.includes(input)) {
                this.searchFields.push(input);
            }
        });
        setTimeout(() => this.dynamicInputsLoaded = true, 200);
    }

    protected isQueryField(name: string): boolean {
        return this.query && this.query.length ? this.query.some(el => el.property == name) : false;
    }

    protected getValue(name: string): any {
        if (this.query && this.query.length) {
            const element = this.query.find(el => el.property == name);
            if (element) {
                return element.value;
            }
        }
        return this.savedFieldsValues ? this.savedFieldsValues[name] : null;
    }

    protected removeQueryFields(body: any): any {
        const keys = Object.keys(body);
        keys.forEach(key => {
            if (this.query.some(el => el.property == key)) {
                delete body[key];
                delete body[key + '-predicate'];
            }
        });
        return body;
    }

    protected getQueryValueWithSuffixes(value: any, predicate: string, property: CustomPropertyDefinition): string {
        const SPECIAL_COMMA = "\\,";
        let propertyType = property ? property.type : null;
        if (predicate == null || predicate == '') {
            predicate = 'eq';
        }
        if (propertyType == 'DATE') {
            value = DatetimeHelper.getMillis(value);
        } else if (propertyType == 'BOOLEAN') {
            predicate = 'eq';
        } else if (typeof value === 'string') {
            value = value.replace(SPECIAL_COMMA, "SPECIAL_COMMA");
            let splittedValues = value.split(',');
            splittedValues = splittedValues.map(value => { return value.replace('SPECIAL_COMMA', SPECIAL_COMMA) });
            if (predicate == 'beginsWith') {
                splittedValues.forEach((value, index) => splittedValues[index] = value + '*');
                predicate = 'like';
            } else if (predicate == 'endsWith') {
                splittedValues.forEach((value, index) => splittedValues[index] = '*' + value);
                predicate = 'like';
            } else if ((predicate == 'contains') || (predicate == 'notContains')) {
                splittedValues.forEach((value, index) => splittedValues[index] = '*' + value + '*');
                predicate = predicate == 'contains' ? 'like' : 'notLike';
            }
            if (predicate == 'isEmpty' || predicate == 'isNotEmpty') {
                return predicate == 'isEmpty' ? "eq;" : "ne;"
            } else {
                return predicate + ";" + splittedValues.join(',');
            }
        }
        if (predicate == 'isEmpty' || predicate == 'isNotEmpty') {
            return predicate == 'isEmpty' ? "eq;" : "ne;"
        } else {
            return predicate + ";" + value;
        }
    }

    updateLocalStorage(fieldsToSaveBody: any): void {
        if (fieldsToSaveBody && Object.keys(fieldsToSaveBody).some(key => fieldsToSaveBody[key] != null && fieldsToSaveBody[key] != '')) {
            this.savedFieldsValues = fieldsToSaveBody;
            localStorage.setItem(this.localStorageKey, JSON.stringify(fieldsToSaveBody));
        } else {
            this.savedFieldsValues = null;
            localStorage.setItem(this.localStorageKey, null);
        }
    }

    emitExport(): void {
        this.export.emit();
    };

    emitImport(body: FormData): void {
        this.import.emit(body);
    };

    protected updateAdvancedSearchAddibleProperties(elementProperties: CustomPropertyDefinition[], namePrefix: string, labelPrefix: string): void {
        let properties = elementProperties.map(p => {
            return {
                name: namePrefix + p.name,
                label: (labelPrefix && p.name == 'name' ? this.localizationPipe.transform(labelPrefix) + ' ' : '') + (p.label || p.name)
            }
        });
        properties.forEach(prop => {
            if (!this.advancedSearchAddibleProperties.some(advancedSearchProp => advancedSearchProp.name == prop.name)) {
                this.advancedSearchAddibleProperties.push(prop);
            }
        });
    }

    protected waitForAdvancedSearchRenderedAndPerformSearch(): void {
        if (this.advancedSearchEditor) {
            this.advancedSearch();
        } else {
            setTimeout(() => this.waitForAdvancedSearchRenderedAndPerformSearch(), 50)
        }
    }

    emitOpenExternalAddPropertiesDialogAction(advancedSearchProperties: { name: string, label: string }[]): void {
        this.openExternalAddPropertiesDialogAction.emit(advancedSearchProperties);
    }

    protected doSimpleSearch(body: any): void {
        if (this.query && this.query.length) {
            body = Object.assign({}, body, this.encodedQueryFields);
        }
        this.simpleSearchKey = body.key;
        localStorage.setItem(this.localStorageKey, null);
        this.savedFieldsValues = null;
        this.loadData(body.key, body);
    }

    protected resetInitialVisibleProperties(): void {
    }

    protected subscribeToQueryFieldRef(): void {
        this.fieldServiceSubscription = this.fieldService.subscribeToFields([this.queryFieldRef]).subscribe(fieldsMap => this.manageFilterValue(fieldsMap));
    }

    protected manageFilterValue(fieldsMap: { [fields: string]: any }): void {
        let advancedSearchBody = fieldsMap[this.queryFieldRef];
        if (advancedSearchBody != null) {
            const body = Object.assign({}, advancedSearchBody, this.encodedQueryFields);
            this.updateData.emit(body);
        }
    }

    initAllConfigurations(): void {
        this.advancedSearchConfiguration = null;
        setTimeout(() => this.initConfigurations(), 10);
        if (this.propertiesInputs) {
            this.propertiesInputs.advancedSearchDynamicInputsConfiguration = null;
            setTimeout(() => this.propertiesInputs.initConfiguration(), 10);
        }
    }

    protected getSearchFields(userSearchFields: string[]): string[] {
        if (!userSearchFields) {
            userSearchFields = [];
        }
        this.searchInputPropertyFields?.forEach(input => {
            if (!userSearchFields.includes(input)) {
                userSearchFields.push(input);
            }
        });
        return userSearchFields;
    }

    performAdvancedSearch($event): void {
        this.advancedSearch($event);
        this.advancedSearchAction.emit();
    }

    performClearAll($event): void {
        this.clearAll($event);
        this.clearAllAction.emit();
    }

    performSimpleSearchAction(action: string): void {
        this.simpleSearchActionEvent.emit(action);
    }
}