import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { getSemtioyWidgets } from '../../common/helper';
import { SharedModule } from '../../shared/shared.module';
import { RecipeEditDialogComponent } from '../recipe-list/recipe-edit-dialog.component';
import { RecipeListItemComponent } from '../recipe-list/recipe-list-item.component';
import { RecipeListComponent } from '../recipe-list/recipe-list.component';
import { RecipeParameterEditorComponent } from '../recipe-list/recipe-parameter-editor.component';
import { RecipeParameterSelectorComponent } from '../recipe-list/recipe-parameter-selector.component';
import { ConfigurationParameterEntryComponent } from './configuration-parameter-entry.component';
import { ConfigurationParameterFieldComponent } from './configuration-parameter-field.component';
import { ConfigurationParametersComponent } from './configuration-parameters.component';
import { RecipeParameterViewerDialogComponent } from '../recipe-list/recipe-parameter-viewer-dialog.component';



@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        ConfigurationParametersComponent,
        ConfigurationParameterEntryComponent,
        ConfigurationParameterFieldComponent,

        RecipeListComponent,
        RecipeListItemComponent,
        RecipeEditDialogComponent,
        RecipeParameterSelectorComponent,
        RecipeParameterEditorComponent,
        RecipeParameterViewerDialogComponent
    ],
    exports: [
        ConfigurationParametersComponent,
        ConfigurationParameterEntryComponent,
        RecipeListComponent
    ]
})
export class ConfigurationParametersModule { }

if (process.env.ENV !== 'production') {
    const semiotyWidgets = getSemtioyWidgets();
    semiotyWidgets['configurationParameters'] = ConfigurationParametersComponent;
}