import { forwardRef, Inject, Injectable, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Properties } from '../../common/properties';
import { CustomPropertyDefinition, DetailsWidgetData, Partner, Value } from '../../model/index';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { DataService } from '../../service/data.service';
import { SocketService } from '../../service/socket.service';
import { CompositePartComponent, CompositePartMode, MetricDetailComponent, PropertyComponent } from '../../shared/component';
import { DefaultCompositePartPipe } from '../../shared/pipe/default-composite-part.pipe';
import { DefaultContactsListPipe } from '../../shared/pipe/default-contacts-list.pipe';
import { DetailsWidgetService } from '../shared/details-widget.service';

@Injectable()
export class PartnerDetailsService extends DetailsWidgetService<Partner> {

    private socketSubscriptionIds: number[];

    constructor(
        @Inject(forwardRef(() => SocketService)) private socketService: SocketService,
        @Inject(forwardRef(() => DataService)) protected dataService: DataService,
        @Inject(forwardRef(() => CustomPropertyService)) protected customPropertyService: CustomPropertyService,
        @Inject(forwardRef(() => AuthenticationService)) protected authenticationService: AuthenticationService
    ) {
        super(dataService, customPropertyService, authenticationService);
    }

    destroy(): void {
        if (this.socketSubscriptionIds) {
            this.socketSubscriptionIds.forEach(id => {
                this.socketService.delete(id);
            });
            this.socketSubscriptionIds = null;
        }
    }

    init(components: QueryList<any>, partner: Partner): DetailsWidgetData[] {
        if (components && components.length) {
            this.socketSubscriptionIds = [];
            this.element = partner;
            return components.map(component => this.processComponent(component));
        }
        return [];
    }

    private processComponent(component: MetricDetailComponent | PropertyComponent | CompositePartComponent): DetailsWidgetData {
        const subject: BehaviorSubject<string> = new BehaviorSubject('');
        if (component instanceof PropertyComponent) {
            const property = component as PropertyComponent;
            let isFile = false;
            let customPropertyType;
            let objId;
            let propertyDef: CustomPropertyDefinition;
            if (property.name.startsWith('properties')) {
                const customPropName = property.name.substr(11);
                let defaultValue = '';
                let propertyPath = 'properties.';
                customPropertyType = CustomPropertyType.Partner;
                objId = this.element.id;
                propertyDef = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(customPropertyType, component.name.substring(propertyPath.length));
                defaultValue = propertyDef ? propertyDef.value : '';
                isFile = propertyDef ? propertyDef.type == 'FILE' : false;
                let value = _.get(this.element, property.name, defaultValue);
                value = this.getDictionaryValue(propertyDef, value);
                subject.next(value);
                return {
                    name: this.getPartnerPropertyLabel(customPropName, property),
                    originalName: Promise.resolve(customPropName),
                    value: subject.asObservable(),
                    filter: this.getFilterProperty(property),
                    unit: null,
                    showLabel: property.showLabel,
                    downloadable: isFile,
                    metricNameOrPropertyId: propertyDef ? propertyDef.id : null,
                    customPropertyType: customPropertyType,
                    objId: objId,
                    description: property.description || propertyDef?.description,
                    filterArg: propertyDef ? { property: propertyDef, templateElement: property.getTemplateInputMap() } : null
                };
            } else {
                const propertyInfo = Properties.Partner[property.name];
                subject.next(_.get(this.element, propertyInfo.path, ''));
                return {
                    name: Promise.resolve(property.label || propertyInfo.label),
                    originalName: Promise.resolve(propertyInfo?.label || property.name),
                    value: subject.asObservable(),
                    filter: property.filter || propertyInfo.defaultFilter,
                    unit: null,
                    showLabel: property.showLabel,
                    downloadable: isFile,
                    metricNameOrPropertyId: propertyDef ? propertyDef.id : null,
                    customPropertyType: customPropertyType,
                    objId: objId,
                    description: property.description || propertyDef?.description,
                    filterArg: propertyDef ? { property: propertyDef, templateElement: property.getTemplateInputMap() } : null
                };
            }
        } else if (component instanceof CompositePartComponent) {
            const compositePart = component;
            this.element.constructor = Partner;
            return {
                name: Promise.resolve(compositePart.label || compositePart.name),
                originalName: Promise.resolve(compositePart.name),
                value: compositePart.get(this.element, CompositePartMode.DETAIL).pipe(map(val => {
                    if (val) {
                        const v = val as Value;
                        return v.value;
                    }
                })),
                filter: compositePart.filter || DefaultCompositePartPipe,
                unit: null,
                showLabel: compositePart.showLabel,
                downloadable: false,
                metricNameOrPropertyId: null,
                customPropertyType: null,
                objId: null,
                description: compositePart.description
            };
        } else {
            throw new Error('Widget definition error: some components are not valid');
        }
    }

    private getPartnerPropertyLabel(customPropName: string, property: PropertyComponent): Promise<string> {
        if (property.label) {
            return Promise.resolve(property.label);
        }
        let cp = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Partner, customPropName);
        if (cp) {
            return Promise.resolve(cp.label || cp.name);
        } else {
            return Promise.resolve(property.name);
        }
    }

    private getFilterProperty(property: PropertyComponent): string | Function {
        if (property.name.startsWith('properties.')) {
            const definitionPartner = this.customPropertyService.getCustomPropertyDefinitionByTypeAndName(CustomPropertyType.Partner, property.name.substr(11));
            if (definitionPartner && definitionPartner.type === 'CONTACTS') {
                return DefaultContactsListPipe;
            } else {
                return property.filter;
            }
        } else {
            return property.filter;
        }
    }
}