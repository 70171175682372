import { forwardRef, Inject, Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ExpandableWidgetDialogComponent } from "../widget/shared/expanded-widget-dialog.component";

@Injectable()
export class ExpandableWidgetDialogService {

    constructor(
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) { }

    openDialog(data: ExpandableWidgetDialogData, viewContainerRef: ViewContainerRef): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.minWidth = '25%';
        dialogConfig.minHeight = '300px';
        dialogConfig.data = data;
        dialogConfig.viewContainerRef = viewContainerRef;
        this.dialog.open(ExpandableWidgetDialogComponent, dialogConfig);
    }
}

export class ExpandableWidgetDialogData {
    title: string;
    templateName: string;
}