import { Component, EventEmitter, forwardRef, Inject, Input, Output, ViewChild } from "@angular/core";
import { Recipe } from "../../model/recipe";
import { ParameterService } from "../../service/parameter.service";
import { ModalComponent } from "../../shared/component";
import { RecipeImportDialogComponent } from '../../shared/component/recipe-import-dialog/recipe-import-dialog.component';
import { HttpUtility } from '../../utility';
import { isEmptyObject } from "../../common/helper";


@Component({
    selector: 'recipe-list-item',
    template: require('./recipe-list-item.component.html'),
    styles: [require('./recipe-list-item.component.css')],
    providers: [ParameterService]
})
export class RecipeListItemComponent {

    exportParameters: string;

    @Input() recipe: Recipe;

    @Input() writePermission: boolean;

    @Input() isDisabled: boolean;

    @Input() isCustomerContext: boolean;

    @Output() editAction = new EventEmitter();

    @Output() applyAction = new EventEmitter();

    @Output() viewParametersAction = new EventEmitter();

    @Output() importAction = new EventEmitter();

    @ViewChild(RecipeImportDialogComponent) dialog: RecipeImportDialogComponent;

    @ViewChild('confirmApplyAlert') confirmApplyAlert: ModalComponent;

    constructor(
        @Inject(forwardRef(() => HttpUtility)) private httpUtility: HttpUtility,
        @Inject(forwardRef(() => ParameterService)) private parameterService: ParameterService
    ) { }

    isRecipeEmpty(): boolean {
        return isEmptyObject(this.recipe.parameters);
    }

    apply(): void {
        this.applyAction.emit(this.recipe);
        this.cancel();
    }

    emitEdit(): void {
        this.editAction.emit(this.recipe);
    }

    emitViewParameters(): void {
        this.viewParametersAction.emit(this.recipe);
    }

    confirmApply(): void {
        this.confirmApplyAlert.show();
    }

    cancel(): void {
        this.confirmApplyAlert.hide();
    }

    close(): void {
        this.dialog.close();
    }

    export(): void {
        this.parameterService.getConfigurationParametersByThingDefinitionId(this.recipe.thingDefinition.id).then(parameters => {
            this.exportParameters = "Parameter,Value\r\n";
            let recipeParameters = this.recipe.parameters;
            if (recipeParameters) {
                for (let paramName in recipeParameters) {
                    let param = parameters.find(p => p.name == paramName);
                    let row = "";
                    if (param.type == "STRING") {
                        row = paramName + ",\"";
                        if (param.useCustomEditor) {
                            row += this.replaceDoubleQuotes(recipeParameters[paramName]);
                        } else {
                            row += recipeParameters[paramName];
                        }
                        row += "\"";
                    } else {
                        row = paramName + "," + recipeParameters[paramName];
                    }
                    this.exportParameters += row + "\r\n";
                }
            }
            let blob = new Blob([this.exportParameters]);
            this.httpUtility.wrapFileAndDownload({ file: blob, fileName: this.recipe.name + ".csv" });
        }).catch(err => console.log(err));
    }

    private replaceDoubleQuotes(row: string): string {
        return row.replace(/\"/g, '\"\"');
    }

    openDialog(): void {
        this.dialog.open();
    }

    import(file: File): void {
        this.importAction.emit({ recipeId: this.recipe.id, file: file });
        this.close();
    }
}